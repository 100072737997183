import React, { useEffect, useState } from 'react'
import {GoogleMap, Marker, Polygon, useJsApiLoader} from '@react-google-maps/api';
import {config} from "../data/constants";
import {fetchGet} from "../utils/index";
import Lodash from "lodash";

const containerStyle = {
  width: '470px',
  height: '500px'
};

const PolygonMap = (props) => {
  const [gpsPoints, setGpsPoints] = useState(props.gps);
  const [snapedPoints, setSnapedPoints] = useState([]);
  let {isLoaded} = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries: ["drawing"]
  })

  const fetchCordinates = () => {
    let newPoints = []

    let pathChunk = Lodash.chunk(props.gps, 100);
    let pathLength = pathChunk.length
    for(let i = 0; i < pathLength; i++){
      let pathString = pathChunk[i].map(gpsPoint => gpsPoint.lat+','+gpsPoint.long).join('|');
      fetchGet(`https://roads.googleapis.com/v1/snapToRoads?path=${pathString}&interpolate=true&key=${config.GOOGLE_MAPS_API_KEY}`).then(res => {
        if(res){
          let snapedGpsPoints = res.snappedPoints.map(g => ({
            lat: g.location.latitude,
            long: g.location.longitude
          }))
          newPoints = Lodash.concat(snapedGpsPoints, newPoints)
        }  
        
        setSnapedPoints(newPoints);
        setGpsPoints(newPoints); 
        return res
      }).catch(error => console.log(error))
    }
  }

  useEffect(() => {
    if(props.gps.length >= 2){
      fetchCordinates();
    }
  }, [])

  const [map, setMap] = React.useState(null)

  const renderMap = (map, snaped) => {
    setMap(map);
    let bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < gpsPoints.length - 1; i++) {
      if (parseFloat(gpsPoints[i].lat) !== 0.0 || parseFloat(gpsPoints[i].long) !== 0.0) {
        let flightPlanCoordinates = [{lat: parseFloat(gpsPoints[i].lat), lng: parseFloat(gpsPoints[i].long)}, {
          lat: parseFloat(gpsPoints[i + 1].lat),
          lng: parseFloat(gpsPoints[i + 1].long)
        }];

        let color = config.COLLISION_STATE_COLOR_CODE[parseInt(gpsPoints[i]?.color_code || 1)];
        let flightPath = new window.google.maps.Polyline({
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: color,
          strokeOpacity: 1.0,
          strokeWeight: 5,
        });
        
        flightPath.setMap(map);

        let loc = new window.google.maps.LatLng(parseFloat(gpsPoints[i].lat), parseFloat(gpsPoints[i].long));
        bounds.extend(loc);
      }
    }
    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }
  
  useEffect(() => {
    if(props.snapedView === true && snapedPoints){
      setGpsPoints(snapedPoints);
    }else{
      setGpsPoints(props.gps);
    }
  }, [props.snapedView])

  const onLoad = React.useCallback(function callback(map) {
    renderMap(map, props.snapedView);
  }, [props.snapedView, gpsPoints]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const center_polygon = (coordinates) => {
    let x = coordinates.map(c => c.lat);
    let y = coordinates.map(c => c.lng);

    let minX = Math.min.apply(null, x);
    let maxX = Math.max.apply(null, x);

    let minY = Math.min.apply(null, y);
    let maxY = Math.max.apply(null, y);

    return {
      lat: (minX + maxX) / 2,
      lng: (minY + maxY) / 2
    };
  }

  return isLoaded ? (
    <GoogleMap
      key={gpsPoints.length}
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {props.geofencingData ? 
        props.geofencingData.map((d, i) => {
          return (
            <Polygon
              key={i}
              draggable={false}
              editable={false}
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
              }}
              path={d.map_data}
            />
          )
        })
      : null}
      {props.geofencingData ? 
        props.geofencingData.map((d, i) => {
          return(
            <Marker
              key={i}
              label={{text:"Speed Limit: "+ d?.speed_limit || 0, color:'black', fontSize: '18px'}}
              position={center_polygon(d.map_data)}
            />
          )
        })
      : null}
    </GoogleMap>
  ) : <></>
}

export default PolygonMap;