import React, {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../router/AppRouter";
import {Button, Card, Container, Modal, Table, Dropdown, OverlayTrigger, Popover} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {config} from "../data/constants";
import PaginationBar from "./common/PaginationBar";
import {fetchDelete, fetchGet, queryFetchGet} from "../utils";
import BackdropLoader from "./common/BackdropLoader";
import DropdownField from "./common/DropdownField";
import LazyLoad from 'react-lazyload';
import {useHistory} from "react-router-dom";
import upDownArrow from "../static/images/up-down-arrow.svg";
import upArrow from "../static/images/up-arrow.svg";
import downArrow from "../static/images/down-arrow.svg";
import { useQuery } from 'react-query';
import $ from "jquery";
import DataTable from 'datatables.net';

const RideMedia = (props) => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const getRideMediaURL = config.API_URL + "/api/v1/aws/get-media";
  const downloadRideMediaURL = config.API_URL + "/api/v1/aws/download-media";
  const deleteRideMediaURL = config.API_URL + "/api/v1/aws/delete-media";
  const getFinalResultURL = config.API_URL + "/api/v1/trip-final-result/get-trip-final-result";
  let filterByFormikRef = useRef(null);
  let downloadAnchorRef = useRef(null);

  const [filterBy, setFilterBy] = useState({});
  const [medias, setMedias] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [combinedDownload, setCombinedDownload] = useState(false);
  const [allUsers, setAllUsers] = useState([{title: "All", value: 0}]);
  const [selectedUser, setSelectedUser] = useState(0);
  const [finalResultData, setfinalResultData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false)
  const [deletingStatus, setDeletingStatus] = useState(false)
  const [tableData, setTableData] = useState(false); 
  const [rideMediaRedirect, setrideMediaRedirect] = useState(new URLSearchParams(props.location.search).get("trid_id"));

  useEffect(() => {
    setrideMediaRedirect(new URLSearchParams(props.location.search).get("trid_id"));
    setPage(1);
  }, [props.location.search])
 

  useEffect(() => {
    let isAllSelected = true;
    let isOneSelected = false;
    for (let [key, val] of Object.entries(selectedRows)) {
      if (val === false) {
        isAllSelected = false;
      } else {
        isOneSelected = true;
      }
    }
    setSelectAll(isAllSelected);
    setCombinedDownload(isOneSelected);
  }, [selectedRows]);

  useEffect(() => {
    setfinalResultData(null);
    if(rideMediaRedirect !== null){
      fetchGet(getFinalResultURL + `?trip_id=`+rideMediaRedirect).then(res => {
        setfinalResultData(res.data.tripFinalResult);
      }).catch(err => {

      })
    }
  }, [rideMediaRedirect])
  
  const getRideMedia = async(_page, _filterBy = null, _sortBy = null, _operator_id) => {
    let filterByString = "";
    let operatorIdString = "";
    let sortByString = "&sort_by=created_at-desc";
    let imageTypeString = "&entity_type=RIDE_IMAGES";
    for (let [key, val] of Object.entries(_filterBy)) {
      filterByString += `&${key}=${val}`;
    }
    if (_sortBy) {
      sortByString = `&sort_by=${_sortBy}`
    }

    if (_operator_id && _operator_id.length > 0) {
      operatorIdString = `&operator_id=${JSON.stringify(_operator_id)}`
    }
    let response = queryFetchGet(getRideMediaURL + `?page=${_page}${filterByString}${sortByString}${operatorIdString}${imageTypeString}` + `${rideMediaRedirect !== null ? "&trid_id=true&name=" + rideMediaRedirect : ""}`);
    return await response;
  }

  const downloadRideMedia = async (_selectedRows) => {
    let ids = "";
    for (let [key, val] of Object.entries(_selectedRows)) {
      if (val === true) {
        ids += `${key},`;
      }
    }
    ids = ids.replace(/(^\s*,)|(,\s*$)/g, ''); // remove trailing comma
    if (ids !== "") {
      let response = queryFetchGet(downloadRideMediaURL + `?ids=${ids}` + `&entity_type=RIDE_IMAGES`);
      return await response;
    }
  }
  const rideMedia = useQuery(['ride-get-media', rideMediaRedirect, page, filterBy, sortBy, userContext.state?.operator_id], () => getRideMedia(page, filterBy, sortBy, userContext.state?.operator_id), {enabled: false});
  const rideDownloadMedia = useQuery(['ride-download-media', selectedRows], () => downloadRideMedia(selectedRows), {enabled: false});

  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": "/ride-media"}});
    rideMedia.refetch();
  }, [page, filterBy, sortBy, rideMediaRedirect, userContext.state?.operator_id]);

  useEffect(()=> {
    if(rideMedia.data){
      $('#example').DataTable().destroy();
        setTimeout(() => {
          initialDatatable();
      }, 0);

      setMedias(rideMedia.data.data["media"]["rows"]);
        let _selectedRows = {};
        for (let media of rideMedia.data.data["media"]["rows"]) {
          _selectedRows[media.id] = false;
        }
        setSelectedRows(_selectedRows);
        setSelectAll(false);
        setTotalPageCount(rideMedia.data.data["total_page"]);
        if (Object.keys(rideMedia.data.data["users"]).length) {
          let _users = [{title: "All", value: 0}];
          for (let [key, val] of Object.entries(rideMedia.data.data['users'])) {
            _users.push({title: val, value: key});
          }
          setAllUsers(_users);
        }
    }
    if(rideDownloadMedia.data){
      downloadAnchorRef.current.href = config.API_URL + rideDownloadMedia.data.data["download_link"];
      downloadAnchorRef.current.click();
    }
  },[rideMedia.data, rideDownloadMedia.data]);

  const initialDatatable = () => {
    let table = $('#example').DataTable({
      "pageLength": 20, 
      lengthMenu: [[5, 10, 20, 50], [5, 10, 20, 50]],
      "aaSorting": [],
      columnDefs: [
        { width: 200, targets: 1, "orderable": false},
      ]
    });

    setTableData(table);
  }

  const deleteRideMedia = () => {
    let ids = [];
    for (let [key, val] of Object.entries(selectedRows)) {
      if (val === true) {
        if(rideMediaRedirect){
          ids.push(key);
        }else{
          ids.push(medias.find(d => d.id === key)?.trip_id)
        }
      }
    }
    if (ids !== []) {
      fetchDelete(deleteRideMediaURL, rideMediaRedirect ? {"ids": ids} : {"trip_ids": ids}).then(res => {
        if (res.success) {
          setDeletingStatus(false);
          setDeleteModal(false);
          rideMedia.refetch(); 
        }
      }).catch(err => {
      });
    }
  }

  function convertUTCDateToLocalDate(date) {
    return new Date(date).toLocaleString();
  }

  return (
    <>
      <BackdropLoader showLoader={rideMedia.isLoading}/>
      <section>
        <Container fluid={true}>
          <div className={"justify-content-between d-flex mb-2"}>
            <div className={"mt-2"}>
              &nbsp;
            </div>
            <div className={"justify-content-end d-flex mb-2"}>
              <Dropdown className={"mx-2"}>
                <Dropdown.Toggle variant="primary" className={"fs-11-375px"}>
                  Bulk Options
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item disabled={!combinedDownload} onClick={() => {
                    rideDownloadMedia.refetch();
                  }}>Download</Dropdown.Item>
                  <Dropdown.Item disabled={!combinedDownload} onClick={() => setDeleteModal(true)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button variant={"secondary"} size={"sm"} className={"fs-11-375px"} onClick={() => {
                filterByFormikRef.resetForm();
                setFilterBy({});
                setrideMediaRedirect(null);
              }}>Reset</Button>
            </div>
          </div>
          {/* <Card>
            <Formik
              initialValues={{name: "", user_id: ""}}
              onSubmit={(values => {
                setPage(1);
                setFilterBy(values);
                setrideMediaRedirect(null);
              })}
              innerRef={p => {
                filterByFormikRef = p
              }}
            >
              {formik => (
                <Form>
                  <Table hover responsive size="sm" className={"mb-0"}>
                    <thead>
                    <tr>
                      <th></th>
                      <th>Image</th>
                      <th>Trip Id</th>
                      <th>Name</th>
                      <th>App Version</th>
                      <th>Device</th>
                      <th>Created By</th>
                      <th scope="col" className={"show-hand"} onClick={() => {
                        let me = "created_at-asc";
                        if (sortBy === me) {
                          setSortBy("created_at-desc");
                          me = "created_at-desc";
                        } else {
                          setSortBy("created_at-asc");
                        }
                      }}>Created at<img alt={""}
                                        src={sortBy === "created_at-asc" ? downArrow : sortBy === "created_at-desc" ? upArrow : upDownArrow}
                                        className={"sort-arrow"}
                      /></th>
                      {rideMediaRedirect ? null : <th>Action</th>}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-check-input" type="checkbox" value="" checked={selectAll}
                          onChange={(event) => {
                            let updatedState = {};
                            for (let key of Object.keys(selectedRows)) {
                              updatedState[key] = event.target.checked;
                            }
                            setSelectedRows(updatedState);
                          }}/>
                      </td>
                      <td>
                        <Field
                          name="name"
                          render={({field, meta}) => (
                            <input
                            className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                            placeholder={"Enter Name"}/>
                            )}
                            />
                      </td>
                      <td/>
                      <td/>
                      <td/>
                      <td>
                        <Field
                          name="user_id"
                          render={({field, meta}) => (
                            <DropdownField
                            options={allUsers} selectedValue={selectedUser} label={null} initialOption={null}
                            name={"user_id"}
                            handleChange={async (event) => {
                              setSelectedUser(event.target.value);
                              formik.setFieldValue("user_id", event.target.value);
                              await formik.submitForm();
                            }}
                            onOptionChange={(event) => {
                              
                            }}/>
                            )}
                            />
                      </td>
                      <td/>
                      {rideMediaRedirect ? null : <td/>}
                    </tr>
                    {(medias && medias.length > 0) ? medias.map((media, index) => (
                      <tr key={media.id}>
                        <td>
                          <input
                            className="form-check-input" type="checkbox" value="" checked={selectedRows[media.id]}
                            onChange={(event) => {
                              setSelectedRows(oldSelectedRows => ({
                                ...oldSelectedRows,
                                [media.id]: event.target.checked
                              }));
                            }}/>
                        </td>
                        <td className={"zoomin"}>
                          <LazyLoad once={true}>
                            <img alt={media.id} src={media.path} className={"icon-img"}/>
                          </LazyLoad>
                        </td>
                        <td>
                          {media.trip_id}
                        </td>
                        <td>{media.name}</td>
                        <td>{media.app_ver}</td>
                        <td>{media.device}</td>
                        <td>{media.created_by_user}</td>
                        <td>{convertUTCDateToLocalDate(media["created_at"])}</td>
                        <td>
                          {rideMediaRedirect || !media.trip_id ? null : 
                            <Button variant={"danger"} size={"sm"} className={"mx-2"} onClick={() => {
                              history.push(`ride-media?trid_id=${media.trip_id}`);
                            }}>
                              Details
                            </Button>
                          }
                        </td>
                      </tr>
                    )) : ""}
                    </tbody>
                  </Table>
                  <input type={"submit"} className={"d-none"}/>
                </Form>
              )}
            </Formik>
          </Card>
          <PaginationBar totPages={totalPageCount} currentPage={page} pageClicked={(pageNumber) => {
            setPage(pageNumber);
          }}/> */}
        </Container>
      </section>
      <br/>
      <div>
        <table id="example" className="display" style={{"width":"100%"}}>
          <thead>
            <tr>
              <th style={{display: "none"}}></th>
              <th style={{maxWidth: "10px"}}>
                <input
                  className="form-check-input" type="checkbox" value="" checked={selectAll}
                  onChange={(event) => {
                    // let updatedState = {};
                    // for (let key of Object.keys(selectedRows)) {
                    //   updatedState[key] = event.target.checked;
                    // }
                    let updatedState = {};

                    for(let key of tableData.column(0, { page:'current' }).data().toArray()){
                      updatedState[key] = event.target.checked;
                    }
                    console.log('das', tableData.column(0, { page:'current' }).data().toArray());
                    setSelectedRows(updatedState);
                  }}/>
              </th>
              <th>Trip ID</th>
              <th>Image</th>
              <th>Name</th>
              <th>App Version</th>
              <th>Device</th>
              <th>Created By</th>
              <th>Created At</th>
              {rideMediaRedirect ? <th style={{display: "none"}}/> : <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {(medias && medias.length > 0) ? medias.map((media, index) => (
              <tr key={media.id}>
                <td style={{display: "none"}}>{media?.id}</td>
                <td>
                  <input
                    className="form-check-input" type="checkbox" value="" checked={selectedRows[media.id]}
                    onChange={(event) => {
                      setSelectedRows(oldSelectedRows => ({
                        ...oldSelectedRows,
                        [media.id]: event.target.checked
                      }));
                    }}/>
                </td>
                <td>
                  {media.trip_id}
                </td>
                <td >
                <OverlayTrigger
                  key={media.id}
                  placement="right"
                  overlay={
                    <Popover id={`popover-positioned-${media.id}`} style={{zIndex: '99'}}>
                      <div>
                        <img alt={media.id} src={media.path} style={{height: '100%', width: '100%'}}/>
                      </div>
                    </Popover>
                  }
                >
                  <img alt={media.id} src={media.path} className={"icon-img"}/>
                </OverlayTrigger>
                </td>
                <td>{media.name}</td>
                <td>{media.app_ver}</td>
                <td>{media.device}</td>
                <td>{media.created_by_user}</td>
                <td>{convertUTCDateToLocalDate(media["created_at"])}</td>
                {rideMediaRedirect || !media.trip_id ? <td style={{display: "none"}}/> : 
                  <td>
                    <Button variant={"danger"} size={"sm"} className={"mx-2"} onClick={() => {history.push(`ride-media?trid_id=${media.trip_id}`)}}>
                      Details
                    </Button>
                  </td>
                }
              </tr>
            )) : ""}
          </tbody>
        </table>
      </div>
      <br/>
      <a className={"d-none"} href={""} download ref={downloadAnchorRef}/>
      <Modal show={deleteModal} onHide={() => {
        setDeleteModal(false);
      }}>
        <Modal.Header closeButton>Delete Data</Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the data?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size={"sm"} className={"mt-2 fs-11-375px"} onClick={() => {
            setDeleteModal(false);
          }}>
            Cancel
          </Button>
          <Button
            className={"mt-2 fs-11-375px"}
            variant="danger" size={"sm"}
            onClick={async () => {
              setDeletingStatus(true)
              deleteRideMedia();
            }}
          >
            {deletingStatus ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default RideMedia;