import fetch from "isomorphic-unfetch";
import config from "../data/constants";
import {logout} from "./actions";

export const getHeaders = () => {
  const headers = {
    "Content-Type": "application/json"
  };
  var accessToken = localStorage.getItem("jwt");
  if (accessToken) {
    headers["Authorization"] = accessToken;
  }
  return headers;
};

const tryJson = async (res) => {
  try {
    return await res.json();
  } catch (_error) {
    return undefined;
  }
};

export const fetchPost = async (url, data) => {
  return await fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then(async (resp) => {
      if (resp.status === config.config.HTTP_STATUS_CODE.UNAUTHORISED) {
        logout(null, false);
      }
      if (!resp.ok) {
        const body = await tryJson(resp);
        throw new Error(body ? body.message : "Something went wrong");
      }
      return await resp.json();
    })
    .catch((err) => err);
};

export const fetchGet = async (url) => {
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  })
    .then(async (resp) => {
      if (resp.status === config.config.HTTP_STATUS_CODE.UNAUTHORISED) {
        logout(null, false);
      }
      if (!resp.ok) {
        const body = await tryJson(resp);
        throw new Error(body ? body.message : "Something went wrong");
      }
      return await resp.json();
    })
    .catch((err) => err);
};

export const fetchDelete = async (url, data) => {
  return await fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then(async (resp) => {
    if (resp.status === config.config.HTTP_STATUS_CODE.UNAUTHORISED) {
      logout(null, false);
    }
    if (!resp.ok) {
      const body = await tryJson(resp);
      throw new Error(body ? body.message : "Something went wrong");
    }
    return await resp.json();
  }).catch((err) => err);
};

export const fetchGetRunSample = async (url, id, filter) => {
  return await fetch(`${url}/${id}?${filter}`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then(async (resp) => {
      if (resp.status === config.config.HTTP_STATUS_CODE.UNAUTHORISED) {
        logout(null, false);
      }
      if (!resp.ok) {
        const body = await tryJson(resp);
        throw new Error(body ? body.message : "Something went wrong");
      }
      return await resp.json();
    })
    .catch((err) => err);
};

export const queryFetchGet = async (url) => {
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  })
    .then(async (resp) => {
      if (resp.status === config.config.HTTP_STATUS_CODE.UNAUTHORISED) {
        logout(null, false);
      }
      if (!resp.ok) {
        const body = await tryJson(resp);
        throw new Error(body ? body.message : "Something went wrong");
      }
      return await resp.json();
    })
};

export const fetchPut = async (url, data) => {
  return await fetch(url, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then(async (resp) => {
      if (resp.status === config.config.HTTP_STATUS_CODE.UNAUTHORISED) {
        logout(null, false);
      }
      if (!resp.ok) {
        const body = await tryJson(resp);
        throw new Error(body ? body.message : "Something went wrong");
      }
      return await resp.json();
    })
    .catch((err) => err);
};

export const fetchUploadFile = async (url, data) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('model_type', data.model_type);
  return await fetch(url, {
      method: "POST",
      headers: {
          "authorization": localStorage.getItem("jwt")
      },
      body: formData // This is your file object
  }).then(
      async (response) => {
          return await response.json(); // if the response is a JSON object
      }
  ).catch(
      async (error) => {
          await console.log(error); // Handle the error response object
      }
  );
};