import React, {useContext, useEffect, useRef, useState} from "react";
import $ from "jquery";
import {config} from "../data/constants";
import {fetchDelete, fetchGet, fetchPut, queryFetchGet} from "../utils";
import { Button, Card, Container, Modal, Table } from "react-bootstrap";
import {Link} from "react-router-dom";
import * as YUP from "yup";
import {Form, Formik} from "formik";
import {InputField} from "./common/InputField";
import {ButtonLoader} from "./common/ButtonLoader";
import Lodash from "lodash";
import {UserContext} from "../router/AppRouter";
import BackdropLoader from "./common/BackdropLoader";
import { useQuery } from "react-query";
import DropdownField from "./common/DropdownField";
import { number } from "prop-types";
import DataTable from 'datatables.net';

const MediaPlayer = (props) => {
  const userContext = useContext((UserContext));
  const userUrl = config.API_URL + "/api/v1/user/";
  const operatorUrl = config.API_URL + "/api/v1/user/operators";
  const mediaUrl = config.API_URL + "/api/v1/media-player/get-media-player-data";

  const [operatorId, setOperatorId] = useState();
  const [filterOperatorId, setFilterOperatorId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [operatorList, setOperators] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [tableData, setTableData] = useState(false); 
  const [selectedTable, setSelectedTable] = useState([]);
  const [mediaRedirect, setMediaRedirect] = useState(new URLSearchParams(props.location.search).get("trid_id"));

  //this code will remove the alert thrown by datatable
  $.fn.dataTable.ext.errMode = 'none';

  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": props.location.pathname}});
  }, []);

  useEffect(() => {
    setMediaRedirect(new URLSearchParams(props.location.search).get("trid_id"));
  }, [props.location.search])

  const fetchData = async (url) => {
      let response = await queryFetchGet(url)
      return await response;
  }

  const fetchMediaData = async (url, _mediaRedirect) => {
      if(_mediaRedirect){
        url += `?trip_id=${_mediaRedirect}`
      }

      let response = await queryFetchGet(url)
      return await response;
  }

  const operatorReq = useQuery('manageOperatorData', () => fetchData(operatorUrl), {enabled: false})
  const mediaReq = useQuery(['getMediaPlayerData', userContext.state?.operator_id, filterOperatorId], () => fetchMediaData(mediaUrl, mediaRedirect), {enabled: false})

  useEffect(() => {
      // fetch("https://api.iwaycode.com/media/sv_player/test.json", {crossDomain:true, mode: 'no-cors'}).then(res => console.log('resp', res))
      operatorReq.refetch();
      mediaReq.refetch();
  }, [userContext.state?.operator_id]);

  useEffect(() => {
      if (operatorReq.data && operatorReq.data.data) {
        let operatorList = operatorReq.data.data.operatorList
        let _operators = [];
        for (let i = 0; i < operatorList.length; i++) {
          _operators.push({value: operatorList[i]["id"], title: operatorList[i]["first_name"]+operatorList[i]["last_name"]})
        }
        setOperators(_operators);
        $('#operator_table').DataTable().destroy();
        setTimeout(() => {
          initialDatatable();
        }, 0);
      }
  }, [operatorReq.data]) 

  useEffect(() => {
      if(mediaReq.data){
          setMediaData(mediaReq.data.data);
      }
  }, [mediaReq.data, mediaData])

  const initialDatatable = () => {
    let table = $('#operator_table').DataTable({
      paging: false,
      "aaSorting": []
    });

    setTableData(table);
  }

  useEffect(() => {
    mediaReq.refetch();
  }, [filterOperatorId])

  function convertUTCDateToLocalDate(date) {
    return new Date(date).toLocaleString();
  }

  const selectForAnalyze = (id) => {
    let data = Lodash.xor(selectedTable, [id]);
    setSelectedTable(data);
  }

  const openPlayer = () => {
    let data = selectedTable.map(d => mediaData.find(med => med.id == d));
    let base_url = config.API_URL
    let json = data.map(d => d.json_file);
    let videoUrl = data[0].video_file;
    window.open(`https://synkl.com/media-player?base_url=${base_url}&video_url=${videoUrl}&json=${JSON.stringify(json)}`);
  }

  return (
    <>
      <BackdropLoader showLoader={operatorReq.isLoading}/>
      <section>
        <Container fluid={true}>
          <div className={"justify-content-between d-flex"}>
            <div className={"h2"}>Media Player Data</div>
          </div>
        </Container>
        <div style={{padding: "0px 10px"}}>
          <br/>
          <Button variant={"warning"} size={"sm"} className={"mx-2 fs-11-375px"} onClick={() => openPlayer()}>
            Analyze
          </Button>
          <table id="operator_table" className="display" style={{"width":"100%"}}>
            <thead>
              <tr>
                <th style={{"width": "100px"}}>Trip Id</th>
                <th>Json</th>
                <th>Video</th>
                <th style={{"width": "200px"}}>Created At</th>
              </tr>
            </thead>
            <tbody>
            {(mediaData && mediaData.length > 0) ? mediaData.map((media, index) => (
              <tr key={index} onClick={() => selectForAnalyze(media.id)} style={{cursor: "pointer", background: selectedTable.find(d => d == media.id) ? '#bfbfbf': ''}}>
                <td style={{"width": "100px"}}>
                  <div>{media.trip_id}</div>
                </td>
                <td>
                  <div>{media.json_file}</div>
                </td>
                <td>
                  <div>{media.video_file}</div>
                </td>
                <td style={{"width": "200px"}}>
                  <div>{convertUTCDateToLocalDate(media.created_at)}</div>
                </td>
              </tr>
            )) : ""}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
export default MediaPlayer;