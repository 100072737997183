import React, {createContext, useContext, useEffect, useReducer} from "react";
import {BrowserRouter, Route, Switch, useHistory, useLocation} from "react-router-dom";
import Dashboard from "../components/Dashboard";
import TripDashboard from "../components/TripDashboard";
import NotFoundPage from "../components/NotFoundPage";
import ManageOperators from "../components/ManageOperators"
import SignIn from "../components/SignIn";
import Header from "../components/Header";
import {initialState, reducer} from "../reducers/useReducer";
import HelmetDetection from "../components/HelmetDetection";
import AddOperator from "../components/AddOperator";
import AddAdmin from "../components/AddAdmin";
import ManageAdmin from "../components/ManageAdmin";
import ManageUser from "../components/ManageUser";
import AddUser from "../components/AddUser";
import RideMedia from "../components/RideMedia";
import ModelTesting from "../components/ModelTesting";
import OperatorSettings from "../components/OperatorSettings";
import MediaPlayer from "../components/MediaPlayer"

export const UserContext = createContext();

const Routing = () => {
  const history = useHistory();
  const {state, dispatch} = useContext(UserContext);
  let location = useLocation();
  useEffect(() => {
    const userID = JSON.parse(localStorage.getItem("user_id"));
    const userName = JSON.parse(localStorage.getItem("user_name"));
    const operatorId = JSON.parse(localStorage.getItem("operator_id"));
    const userRole = JSON.parse(localStorage.getItem("user_role"));
    if (userID) {
      let pathname = location.pathname;
      if (location.pathname === "/" || location.pathname === "/sign-in") {
        pathname = "/trip-dashboard";
      }
      dispatch({type: "USER", payload: {"user_id": userID, "user_name": userName, "pathname": pathname, operator_id: operatorId, user_role: userRole}});
      history.push(pathname + location.search);
    } else {
      dispatch({type: "CLEAR", payload: {}});
      history.push("/sign-in");
    }
  }, []);
  return (
    <Switch>
      <Route path="/sign-in" component={SignIn}/>
      <Route path="/objects-detected" exact={true} component={Dashboard}/>
      <Route path="/trip-dashboard" exact={true} component={TripDashboard}/>
      <Route path={"/helmet-detection"} exact={true} component={HelmetDetection}/>
      <Route path={"/ride-media"} exact={true} component={RideMedia}/>
      <Route path={"/operators"} exact={true} component={ManageOperators}/>
      <Route path={"/admins"} exact={true} component={ManageAdmin}/>
      <Route path={"/users"} exact={true} component={ManageUser}/>
      <Route path={"/add-operator"} exact={true} component={AddOperator}/>
      <Route path={"/add-admin"} exact={true} component={AddAdmin}/>
      <Route path={"/add-user"} exact={true} component={AddUser}/>
      <Route path={"/model-testing"} exact={true} component={ModelTesting}/>
      <Route path={'/operator-settings'} exact={true} component={OperatorSettings} />
      <Route path={'/media-player'} exact={true} component={MediaPlayer} />
      <Route component={NotFoundPage}/>
    </Switch>
  );
};

const AppRouter = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{state, dispatch}}>
      <BrowserRouter>
        <div className={"bg-off-white h-100vh"}>
          <Header/>
          <Routing/>
        </div>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default AppRouter;
