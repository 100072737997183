import React, {useContext, useEffect, useRef, useState} from "react";
import {config} from "../data/constants";
import {fetchDelete, fetchGet, fetchPut, queryFetchGet} from "../utils";
import { Button, Card, Container, Modal, Table } from "react-bootstrap";
import {Link} from "react-router-dom";
import * as YUP from "yup";
import {Form, Formik} from "formik";
import {InputField} from "./common/InputField";
import {ButtonLoader} from "./common/ButtonLoader";
import Lodash from "lodash";
import {UserContext} from "../router/AppRouter";
import BackdropLoader from "./common/BackdropLoader";
import { useQuery } from "react-query";
import $ from "jquery";
import DataTable from 'datatables.net';

const ManageOperators = (props) => {
  const userContext = useContext((UserContext));
  let editOperatorFormikRef = useRef();

  const validationRules = YUP.object({
      id: YUP.number().required("Id is required."),
      first_name: YUP.string().required("First Name is required."),
      email: YUP.string().required("Email is required."),
      password: YUP.string().required("Password is required.")
  });

  const userUrl = config.API_URL + "/api/v1/user/";
  const operatorUrl = config.API_URL + "/api/v1/user/operators";

  const [showEditOperatorModel, setShowEditOperatorModel] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [operators, setOperators] = useState([]);
  const [tableData, setTableData] = useState(false); 

  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": props.location.pathname}});
  }, [])

  const fetchData = async (url) => {
      let response = await queryFetchGet(url)
      return await response;
  }

  const operatorReq = useQuery('manageOperatorData', () => fetchData(operatorUrl), {enabled: false})
  
  useEffect(() => {
      operatorReq.refetch();
  }, []);

   //this code will remove the alert thrown by datatable
   $.fn.dataTable.ext.errMode = 'none';

  useEffect(() => {
    if(operatorReq.data){
        setOperators(operatorReq.data.data["operatorList"]);
        $('#operator_table').DataTable().destroy();
        setTimeout(() => {
          initialDatatable();
        }, 0);
    }
  }, [operatorReq.data, operators])

  const editOperator = (values) => {
    fetchPut(userUrl, values).then(res => {
      if (res.success) {
        setIsSubmitting(false);
        editOperatorFormikRef.resetForm();
        setShowEditOperatorModel(false);
        operatorReq.refetch();
      }else{
        setIsSubmitting(false);
        alert("Something went wrong.");
      }
    }).catch(err => {
      console.log('Edit Operator Error: ', err)
    });
  }

  const initialDatatable = () => {
    let table = $('#operator_table').DataTable({
      paging: false,
      "aaSorting": []
    });

    setTableData(table);
  }

  return (
    <>
      <BackdropLoader showLoader={operatorReq.isLoading}/>
      <section>
        <Container fluid={true}>
          <div className={"justify-content-between d-flex"}>
            <div className={"h2"}>Operators</div>
            <div className={"align-items-center d-flex"}>
              <Link className={"btn btn-sm btn-primary mx-1 fs-11-375px"} to={`/add-operator`}>
                Add
              </Link>
            </div>
          </div>
          {/* <Card>
            <Table hover responsive size="sm" className={"mb-0"}>
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {(operators && operators.length > 0) ? operators.map((operator, index) => (
                <tr key={index}>
                  <td>
                    <div>{operator?.first_name+ ' '+(operator?.last_name || "")}</div>
                  </td>
                  <td>
                    <div>{operator?.email}</div>
                  </td>
                  <td>
                    <Button variant={"warning"} size={"sm"} className={"mx-2 fs-11-375px"} onClick={() => {
                        editOperatorFormikRef.setFieldValue("id", operator["id"]);
                        editOperatorFormikRef.setFieldValue("first_name", operator["first_name"]);
                        editOperatorFormikRef.setFieldValue("last_name", (operator?.last_name || ''));
                        editOperatorFormikRef.setFieldValue("last_name", operator["last_name"]);
                        editOperatorFormikRef.setFieldValue("email", operator["email"]);
                        editOperatorFormikRef.setFieldValue("password", operator["password"]);
                        editOperatorFormikRef.setFieldValue("role", operator["role"]);
                        setShowEditOperatorModel(true);
                    }
                    }>Edit</Button>
                  </td>
                </tr>)) : ""}
              </tbody>
            </Table>
          </Card> */}
        </Container>
        <div style={{padding: "0px 10px"}}>
          <table id="operator_table" className="display" style={{"width":"100%"}}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {(operators && operators.length > 0) ? operators.map((operator, index) => (
                <tr key={index}>
                  <td>
                    <div>{operator?.first_name+ ' '+(operator?.last_name || "")}</div>
                  </td>
                  <td>
                    <div>{operator?.email}</div>
                  </td>
                  <td>
                    <Button variant={"warning"} size={"sm"} className={"mx-2 fs-11-375px"} onClick={() => {
                        editOperatorFormikRef.setFieldValue("id", operator["id"]);
                        editOperatorFormikRef.setFieldValue("first_name", operator["first_name"]);
                        editOperatorFormikRef.setFieldValue("last_name", (operator?.last_name || ''));
                        editOperatorFormikRef.setFieldValue("last_name", operator["last_name"]);
                        editOperatorFormikRef.setFieldValue("email", operator["email"]);
                        editOperatorFormikRef.setFieldValue("password", operator["password"]);
                        editOperatorFormikRef.setFieldValue("role", operator["role"]);
                        setShowEditOperatorModel(true);
                    }
                    }>Edit</Button>
                  </td>
                </tr>)) : ""}
            </tbody>
          </table>
        </div>
        <br/>
        <Formik
          initialValues={{
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role: "operator"
          }}
          onSubmit={values => {
            editOperator(values);
          }}
          innerRef={(p) => {
            editOperatorFormikRef = p
          }}
          validationSchema={validationRules}>
          {formik => (
            <Modal show={showEditOperatorModel}>
              <Form>
                <Modal.Header>
                  <Modal.Title>Edit Operator</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"pt-0"}>
                    <InputField label={null} type={"text"} name={"id"} className={"d-none"}/>
                    <InputField label={"First Name"} type={"text"} name={"first_name"} placeholder={"Enter the first name"}/>
                    <InputField label={"Last Name"} type={"text"} name={"last_name"} placeholder={"Enter the last name"}/>
                    <InputField label={"Email"} type={"text"} name={"email"} placeholder={"Enter the email"}/>
                    <InputField autocomplete="new-password" label={"Password"} type={"password"} name={"password"} placeholder={"Enter the password"}/>
                    <InputField label={null} type={"text"} name={"role"} className={"d-none"}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size={"sm"} className={"mt-2 fs-11-375px"} 
                        onClick={() => {
                            setShowEditOperatorModel(false);
                            formik.resetForm();
                        }}
                    >
                    Cancel
                  </Button>
                  <ButtonLoader type={"submit"} title={"Update"} classes={"btn btn-sm btn-primary mt-2 fs-11-375px"} loading={isSubmitting}/>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </Formik>
      </section>
    </>
  );
}
export default ManageOperators;