import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {UserContext} from "../router/AppRouter";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {config} from "../data/constants";
import { queryFetchGet } from "../utils";
import { useQuery } from "react-query";
import iwayodeimg from "../static/images/iwaycode.png";

const Header = () => {
  const {state, dispatch} = useContext(UserContext);
  const history = useHistory();
  
  const operatorUrl = config.API_URL + "/api/v1/user/operators";

  const fetchData = async (url) => {
    let response = await queryFetchGet(url)
    return await response;
  }

  const [operatorList, setOperators] = useState([]);
  const operatorReq = useQuery('manageOperatorDataNew', () => fetchData(operatorUrl), {enabled: false})

  const handleLogout = () => {
    localStorage.setItem("jwt", null);
    localStorage.setItem("user_id", null);
    localStorage.setItem("user_name", null);
    localStorage.setItem("operator_id", null);
    localStorage.setItem("user_role", null);
    window.location.reload();
  };

  useEffect(() => {
      operatorReq.refetch();
  }, []);

  useEffect(() => {
    if (operatorReq.data && operatorReq.data.data) {
        let operatorList = operatorReq.data.data.operatorList
        let _operators = [];
        for (let i = 0; i < operatorList.length; i++) {
          _operators.push({value: operatorList[i]["id"], title: operatorList[i]["first_name"]+operatorList[i]["last_name"]})
        }
        setOperators(_operators);
    }
  }, [operatorReq.data])

  return (
    <>
      {state ? (
        <header style={{position: "sticky", top: 0, zIndex: 1}}>
          <Navbar collapseOnSelect expand="lg" variant="light" className={"mb-2 p-0"}>
            <Container className={"m-0"} fluid={true}>
              <Navbar.Brand>
                <img alt={""} src={iwayodeimg} height={"50px"}/>&nbsp;&nbsp;
                {state.user_role === 'super_admin' ? 
                  "iwaycode" 
                : 
                  operatorList.find(op => op.value == state.operator_id[0])?.title || "iwaycode"
                }
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
              <Navbar.Collapse id="responsive-navbar-nav" className={"justify-content-between align-items-center"}>
                <Nav className="me-auto">
                  <Nav.Link as={Link} to={"/trip-dashboard"}
                            className={`${state.pathname === "/trip-dashboard" ? "disabled text-dark fw-bold" : ""}`}>
                    Ride Summary
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/helmet-detection"}
                            className={`${state.pathname === "/helmet-detection" ? "disabled text-dark fw-bold" : ""}`}>
                    Helmet Detection
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/ride-media"}
                            className={`${state.pathname === "/ride-media" ? "disabled text-dark fw-bold" : ""}`}>
                    Ride Media
                  </Nav.Link>
                  {state.user_role === 'super_admin' ? 
                    <Nav.Link as={Link} to={"/operators"}
                          className={`${state.pathname === "/operators" ? "disabled text-dark fw-bold" : ""}`}>
                      Operator
                    </Nav.Link>
                  : null}
                  {state.user_role === 'super_admin' || state.user_role === 'operator' ? 
                    <Nav.Link as={Link} to={"/admins"}
                          className={`${state.pathname === "/admins" ? "disabled text-dark fw-bold" : ""}`}>
                      Admin
                    </Nav.Link>
                  : null}
                  <Nav.Link as={Link} to={"/users"} className={`${state.pathname === "/users" ? "disabled text-dark fw-bold" : ""}`}>
                    User
                  </Nav.Link>
                  {state.user_role === 'operator' ? 
                    <Nav.Link as={Link} to={"/operator-settings"} className={`${state.pathname === "/operator-settings" ? "disabled text-dark fw-bold" : ""}`}>
                      Operator Settings
                    </Nav.Link>
                  : null}
                </Nav>
                {/* {state.user_role === 'operator' ? 
                  <Nav.Link as={Link} to={"/operator-settings"}>
                    <i class="bi bi-gear" style={{color: 'black'}}></i>
                  </Nav.Link>
                : null} */}
                <Nav.Link>
                  <div onClick={() => window.location.reload()}>
                    REFRESH
                  </div>
                </Nav.Link>
                <Nav>
                  <NavDropdown title={state.user_name} id="collasible-nav-dropdown">
                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
