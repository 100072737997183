import React, {useContext, useEffect, useRef, useState} from 'react';
import {Formik, Form} from "formik";
import {useHistory} from "react-router-dom";
import * as YUP from "yup";
import {InputField} from "./common/InputField";
import {Card, Col, Button, Modal, Image} from "react-bootstrap";
import {fetchPost, fetchUploadFile, queryFetchGet} from "../utils/index";
import {config} from "../data/constants";
import {ButtonLoader} from "./common/ButtonLoader";
import DropdownField from "./common/DropdownField"
import { useQuery } from 'react-query';
import { UserContext } from '../router/AppRouter';
import BackdropLoader from "./common/BackdropLoader";

const containerStyle = {
    width: '470px',
    height: '500px'
  };

const ModelTesting = (props) => {
    const userContext = useContext((UserContext));
    const history = useHistory();
    let formikRef = useRef();

    const validationRules = YUP.object({

    });

    const modelListAPI = config.PY_API_URL + "/model_list";
    const modelInferenceAPI = config.PY_API_URL + "/infer";

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentImage, setCurrentImage] = useState({});
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const [currentModelType, setCurrentModelType] = useState();
    const [inferenceData, setInferenceData] = useState();

    const fetchData = async (url) => {
        let response = await queryFetchGet(url)
        return await response;
    }

    const modelListReq = useQuery('modelList', () => fetchData(modelListAPI))
    
    useEffect(() => {

    }, []);

    const postInference = () => {
        setIsSubmitting(true);
        let values = {file: currentImage, model_type: currentModelType}
        fetchUploadFile(modelInferenceAPI, {...values}).then(res => {
            setIsSubmitting(false);
            setInferenceData(res)
        }).catch(err => {
            setIsSubmitting(false);
            console.log('Error: ', err)
        });
    }

  return (
    <>
    {userContext?.state?.user_role == 'super_admin' ? 
      <section>
        <div className={"container-fluid"}>
          <div className={"row justify-content-start d-flex"}>
            <Col sm={12}>
              <Card>
                <Card.Header>Model Testing</Card.Header>
                <Card.Body>
                  <Formik
                    initialValues={{
                      
                    }}
                    onSubmit={values => postInference()}
                    validationSchema={validationRules}
                    innerRef={p => {
                      formikRef = p
                    }}>
                    {formik => (
                      <Form>
                        <div className={"row justify-content-start d-flex"}>
                            <Col sm={3}>
                                <InputField label={"Image"} type={"file"} name={"file"}
                                    placeholder={"Upload a image"} onChange={async (event) => {
                                        setCurrentImage(event?.target?.files[0] || {});
                                        setCurrentImageUrl(event?.target?.files[0] ? URL.createObjectURL(event?.target?.files[0] || '') : '');
                                    }}
                                />
                                {currentImageUrl ? 
                                  <div style={{maxHeight: '450px', maxWidth: '390px'}}>
                                    <Image src={currentImageUrl} style={{maxHeight: '450px', maxWidth: '390px'}}/>
                                  </div>
                                : null}
                            </Col>
                            <Col sm={3}>
                                <div className='my-3'>
                                    <DropdownField
                                        label={"Type Of Inference"} name={"inference_type"} placeholder={"Select Inference Type"}
                                        options={[{value: "cnc", title: 'COLLISION/NON COLLISION'},{value: "helmet", title: 'HELMET'},{value: "road_path", title: 'ROAD PATH'}]}
                                        selectedValue={''} initialOption={"Select Inference Type"}
                                        handleChange={(event) => {
                                            setCurrentModelType(event.target.value);
                                        }}
                                    />
                                </div>
                            </Col>
                            {/* <Col sm={3}>
                                <div className='my-3'>
                                    <DropdownField
                                        label={"Type Of Inference"} name={"inference_type"} placeholder={"Select Inference Type"}
                                        options={[]}
                                        selectedValue={''} initialOption={"Select Inference Type"}
                                        // handleChange={(event) => {
                                        //     formik.setFieldValue("courseId", event.target.value);
                                        //     if (event.target.value) {
                                        //     setChapterCourseId(event.target.value);
                                        //     formik.setFieldValue("levelId", event.target.value);
                                        //     } else {
                                        //     setDropdownLevels([]);
                                        //     }
                                        // }}
                                    />
                                </div>
                            </Col> */}
                            <Col sm={3} className='my-3'>
                                <div style={{marginTop: '16px'}}>
                                    <ButtonLoader
                                        type={"submit"} title={"INFERENCE"}
                                        classes={"btn btn-sm btn-primary mt-2"}
                                        disabled={!currentImageUrl || !currentModelType}
                                        loading={isSubmitting}
                                    />
                                </div>
                            </Col>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <hr/>
                  <div className={"row justify-content-start d-flex"}>
                        {inferenceData ? 
                            <div>
                                <h3>Inference Data</h3>
                                {inferenceData.label ? <div><b>Label:&nbsp;&nbsp;</b>{inferenceData.label}</div> : null}
                                {inferenceData.probability ? <div><b>Probability:&nbsp;</b>{inferenceData.probability.toFixed(2)}</div> : null}
                            </div>
                        : null}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      </section>
    : null}
    </>
  );
};

export default ModelTesting;