import React, {useEffect, useRef, useState, useContext} from "react";
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import {config} from "../data/constants";
import {queryFetchGet} from "../utils/index";
import {Modal} from "react-bootstrap";
import { useQuery } from 'react-query';

const RideSummaryGraph = (props) => {
    const [detectedObjectsData, setDetectedObjectsData] = useState({});
    const [safeData, setSafeData] = useState([]);
    const [cautionData, setCautionData] = useState([]);
    const [warningData, setWarningData] = useState([]);
    const [dangerData, setDangerData] = useState([]);
    const [extremeDangerData, setExtremeDangerData] = useState([]);
    const [radialGraphData, setRadialGraphData] = useState([]);

    const fetchDetectedObjects = async () => {
        let url = config.API_URL + "/api/v1/objects/detected?page=1"+`${"&sort_by=created_at-asc"+"&item_count="+props.totalItemCount+"&trip_id=" + props.trip_id}`;
        let response = queryFetchGet(url);
        return await response;
    };

    const { isLoading, isError, data, refetch } = useQuery(['trips-data-dashboard-modal'], () => fetchDetectedObjects());

    useEffect(() => {
      if (data) {
        setDetectedObjectsData({...data.data["detected_objects"]["rows"]});
        setSafeData(mapData('1', data.data["detected_objects"]["rows"]));
        setCautionData(mapData('2', data.data["detected_objects"]["rows"]));
        setWarningData(mapData('3', data.data["detected_objects"]["rows"]));
        setDangerData(mapData('4', data.data["detected_objects"]["rows"]));
        setExtremeDangerData(mapData('5', data.data["detected_objects"]["rows"]));
        mapRadialData(data.data["detected_objects"]["rows"]);
      }
    }, [data]);

    function convertUTCDateToLocalDate(date) {
        return new Date(date).toLocaleString();
    }

    const mapData = (type, data) => {
        let mappedData = data.map(d => {
            let obj = { hour: convertUTCDateToLocalDate(d.created_at), index: type == d.color_code ? 1 : null, value: 10 }
            return obj;
        })
        return mappedData;
    }

    const mapRadialData = (data) => {
        setRadialGraphData([
            { name: 'SAFE', value: data.filter(d => d.color_code == "1").length},
            { name: 'CAUTION', value: data.filter(d => d.color_code == "2").length},
            { name: 'WARNING', value: data.filter(d => d.color_code == "3").length},
            { name: 'DANGER', value: data.filter(d => d.color_code == "4").length},
            {name: 'EXTREME_DANGER', value: data.filter(d => d.color_code == "5").length}
        ].filter(d => d.value > 0))
    }

    // Map Raidal Graph
    const IndexColorZone = {
        0: "SAFE",
        1: "CAUTION",
        2: "WARNING",
        3: "DANGER",
        4: "EXTREME_DANGER",
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.29;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {IndexColorZone[index]}&nbsp;&nbsp;{`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    function renderTooltip(props){
        const { active, payload } = props;
    
        if (active && payload && payload.length) {
          const data = payload[0] && payload[0].payload;
          return (
            <div
              style={{
                backgroundColor: '#fff',
                border: '1px solid #999',
                margin: 0,
                padding: 10,
              }}
            >
              <p>{data.hour}</p>
            </div>
          );
        }
    
        return null;
    };

    return(
        <Modal show={props.showGraphModel} onHide={() => props.setGraphShowModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '16px'}}><b>Ride Details Graph</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {props.showGraphModel && data && !isLoading ?
                <div style={{display: "flex", minHeight: '280px'}}>
                    <div style={{height: "280px", width: "100%" }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={radialGraphData}
                                    cx="45%"
                                    cy="50%"
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {radialGraphData.map((entry, index) => {
                                        return(
                                            <Cell key={`cell-${index}`} fill={config.COLLISION_STATE_COLOR_CODE[index+1]} />
                                        )}
                                    )}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            : ""}
            </Modal.Body>
        </Modal>
    )
}

export default RideSummaryGraph;