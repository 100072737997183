import React, {useEffect, useRef, useState, useContext} from "react";
import {config} from "../data/constants";
import {fetchGet, queryFetchGet} from "../utils/index";
import {Link, useHistory} from "react-router-dom";
import Flatpickr from "react-flatpickr";
import {Formik, Form, Field} from "formik";
import "flatpickr/dist/flatpickr.min.css";
import upDownArrow from "../static/images/up-down-arrow.svg";
import upArrow from "../static/images/up-arrow.svg";
import downArrow from "../static/images/down-arrow.svg";
import {Modal, FormCheck, OverlayTrigger, Popover} from "react-bootstrap";
import PolygonMap from "./PolygonMap";
import {UserContext} from "../router/AppRouter";
import BackdropLoading from "./common/BackdropLoader";
import PaginationBar from "./common/PaginationBar";
import { useQuery } from 'react-query';
import RideSummaryGraph from './RideSummaryGraph';
import $ from "jquery";
import DataTable from 'datatables.net';

function Dashboard(props) {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const getFinalResultURL = config.API_URL + "/api/v1/trip-final-result/get-trip-final-result";

  const [detectedObjects, setDetectedObjects] = useState({});
  const [trip_images, setTripImages] = useState([]);
  const [location_data, setLocationData] = useState({});
  const [currentDetectedObject, setCurrentDetectedObject] = useState({});
  const [totalItemCount, setTotalItemCount] = useState('');
  const [totalPage, setTotalPage] = useState({});
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [filter, setFilterBy] = useState({});
  const [trackingPath, setTrackingPath] = useState(null);
  const [geofencingData, setGeofencingData] = useState(null)
  const [showModel, setShowModel] = useState(null);
  const [showGraphModel, setGraphShowModel] = useState(null);
  const [tripDashboardRedirect, setTripDashboardRedirect] = useState(new URLSearchParams(props.location.search).get("trip_id"));
  const [finalResultData, setfinalResultData] = useState(null);
  const [snapedStatus, changeSnapedStatus] = useState(true)

  let formik = useRef();

  const fetchDetectedObjects = async (_page, _filter = null, _sortBy = null) => {
    let filterString = "";
    let sortByString = "";
    if (_filter) {
      for (let [key, value] of Object.entries(_filter)) {
        if (key === "color_code" && value) {
          value = Object.keys(config.COLLISION_STATE).find(key => config.COLLISION_STATE[key] === value.toUpperCase());
        }
        filterString += `&${key}=${value}`
      }
    }
    if (_sortBy) {
      sortByString = `&sort_by=${_sortBy}`
    }
    let url = config.API_URL + "/api/v1/objects/detected?page=" + _page + filterString + sortByString + `${tripDashboardRedirect !== null ? "&trip_id=" + tripDashboardRedirect : ""}`;
    let response = queryFetchGet(url);
    return await response;
  };
  
  const { isLoading, isError, data, refetch } = useQuery(['trips-data-dashboard', page, filter, sortBy], () => fetchDetectedObjects(page, filter, sortBy), {enabled: false});

  useEffect(() => {
    if (data) {
        $('#example').DataTable().destroy();
        setTimeout(() => {
          $('#example').DataTable({
            "pageLength": 20, 
            lengthMenu: [[5, 10, 20, 50], [5, 10, 20, 50]],
            "aaSorting": [],
            columnDefs: [
              { width: 200, targets: 0 }
            ]
          });
        }, 0);

        getCity([data.data?.detected_objects?.rows[0]?.gps?.lat, data.data?.detected_objects?.rows[0]?.gps?.long])
        setDetectedObjects({...data.data["detected_objects"]["rows"]});
        setTotalPage(data.data["total_page"]);
        setTripImages(data.data["trip_images"])
        setTotalItemCount(data.data["detected_objects"]["count"])
    }
  }, [data]);

  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": props.location.pathname}});
    refetch();
  }, [page, filter, sortBy, tripDashboardRedirect]);

  useEffect(() => {
    setfinalResultData(null);
    fetchGet(getFinalResultURL + `?trip_id=`+tripDashboardRedirect).then(res => {
      setfinalResultData(res.data.tripFinalResult);
    }).catch(err => {

    })
  }, [])

  function convertUTCDateToLocalDate(date) {
    return new Date(date).toLocaleString();
  }

  function getCity(coordinates) {
    var xhr = new XMLHttpRequest();
    var lat = coordinates[0];
    var lng = coordinates[1];
  
    // Paste your LocationIQ token below.
    xhr.open('GET', `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`,true);
    xhr.send();
    xhr.onreadystatechange = processRequest;
    xhr.addEventListener("readystatechange", processRequest, false);
    
    function processRequest(e) {
        if (xhr.readyState == 4 && xhr.status == 200) {
          setLocationData(JSON.parse(xhr.responseText));
        }
    }
  }
  
  return (
    <div className={"container-fluid"}>
      <BackdropLoading showLoader={isLoading}/>
      <div className={"justify-content-between d-flex mb-2"}>
        <div className={"mt-2"}>
          {finalResultData ? 
            finalResultData.final_result == true ? 'Final Result: WITH HELMET' : 'Final Result: WITHOUT HELMET '
          : null}
        </div>
        <div className={"d-flex justify-content-end my-2 align-items-center"}>
          <div className={""}>
            <button className={"btn btn-sm btn-primary"} onClick={() => {
              setGraphShowModel(true);
            }}>
              Ride Summary Graph
            </button>&nbsp;&nbsp;

            <button className={"btn btn-sm btn-primary"} onClick={() => {
              formik.resetForm();
              setTripDashboardRedirect(null);
              setSortBy(null);
              setFilterBy(null);
              setPage(1);
              history.push("/objects-detected");
            }}>
              Reset
            </button>
          </div>
        </div>
      </div>
      {/* <div className="card">
        <Formik
          initialValues={{
            id: "",
            color_code: "",
            gps: "",
            objects: "",
            trip_id: "",
            source_device: "",
            source_device_meta: "",
            start_date: "",
            end_date: ""
          }}
          innerRef={p => {
            formik = p
          }}
          onSubmit={values => {
            setTripDashboardRedirect(null);
            setFilterBy(values);
            setPage(1);
            history.push("/objects-detected");
          }}
          render={({
                     values,
                     handleSubmit,
                     handleChange,
                     setFieldValue
                   }) => (
            <Form>
              <table
                className={"table font-size-sm mb-0 table-condensed table-hover"}
              >
                <thead>
                <tr>
                  <th scope="col" className={"show-hand w-100px"} onClick={() => {
                    let me = "id-asc";
                    if (sortBy === me) {
                      setTripDashboardRedirect(null);
                      setSortBy("id-desc");
                      history.push("/objects-detected");
                      me = "id-desc";
                    } else {
                      setTripDashboardRedirect(null);
                      setSortBy("id-asc");
                      history.push("/objects-detected");
                    }
                  }}>ID<img alt={""}
                            src={sortBy === "id-asc" ? downArrow : sortBy === "id-desc" ? upArrow : upDownArrow}
                            className={"sort-arrow"}
                  />
                  </th>
                  <th scope="col" className={"show-hand w-100px"} onClick={() => {
                    let me = "color_code-asc";
                    if (sortBy === me) {
                      setTripDashboardRedirect(null);
                      setSortBy("color_code-desc");
                      history.push("/objects-detected");
                      me = "color_code-desc";
                    } else {
                      setTripDashboardRedirect(null);
                      setSortBy("color_code-asc");
                      history.push("/objects-detected");
                    }
                  }}>Color Code<img alt={""}
                                    src={sortBy === "color_code-asc" ? downArrow : sortBy === "color_code-desc" ? upArrow : upDownArrow}
                                    className={"sort-arrow"}
                  /></th>
                  <th scope="col">Ride Media</th>
                  <th scope="col">GPS</th>
                  <th scope="col">Objects</th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "trip_id-asc";
                    if (sortBy === me) {
                      setTripDashboardRedirect(null);
                      setSortBy("trip_id-desc");
                      history.push("/objects-detected");
                      me = "trip_id-desc";
                    } else {
                      setTripDashboardRedirect(null);
                      setSortBy("trip_id-asc");
                      history.push("/objects-detected");
                    }
                  }}>Trip ID<img alt={""}
                                 src={sortBy === "trip_id-asc" ? downArrow : sortBy === "trip_id-desc" ? upArrow : upDownArrow}
                                 className={"sort-arrow"}
                  /></th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "source_device-asc";
                    if (sortBy === me) {
                      setTripDashboardRedirect(null);
                      setSortBy("source_device-desc");
                      history.push("/objects-detected");
                      me = "source_device-desc";
                    } else {
                      setTripDashboardRedirect(null);
                      setSortBy("source_device-asc");
                      history.push("/objects-detected");
                    }
                  }}>Source Device<img alt={""}
                                       src={sortBy === "source_device-asc" ? downArrow : sortBy === "source_device-desc" ? upArrow : upDownArrow}
                                       className={"sort-arrow"}
                  />
                  </th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "source_device_meta-asc";
                    if (sortBy === me) {
                      setTripDashboardRedirect(null);
                      setSortBy("source_device_meta-desc");
                      history.push("/objects-detected");
                      me = "source_device_meta-desc";
                    } else {
                      setTripDashboardRedirect(null);
                      setSortBy("source_device_meta-asc");
                      history.push("/objects-detected");
                    }
                  }}>Source Device META<img alt={""}
                                            src={sortBy === "source_device_meta-asc" ? downArrow : sortBy === "source_device_meta-desc" ? upArrow : upDownArrow}
                                            className={"sort-arrow"}
                  />
                  </th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "created_at-asc";
                    if (sortBy === me) {
                      setTripDashboardRedirect(null);
                      setSortBy("created_at-desc");
                      history.push("/objects-detected");
                      me = "created_at-desc";
                    } else {
                      setTripDashboardRedirect(null);
                      setSortBy("created_at-asc");
                      history.push("/objects-detected");
                    }
                  }}>Created at<img alt={""}
                                    src={sortBy === "created_at-asc" ? downArrow : sortBy === "created_at-desc" ? upArrow : upDownArrow}
                                    className={"sort-arrow"}
                  /></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">
                    <Field
                      name="id"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Enter ID"}/>
                      )}
                    />
                  </th>
                  <td>
                    <Field
                      name="color_code"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Color Code"}/>
                      )}
                    />
                  </td>
                  <td></td>
                  <td>
                    <Field
                      name="gps"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={'{"lat":"0","long":"0"}'}/>
                      )}
                    />
                  </td>
                  <td>
                    <Field
                      name="objects"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={'["CAR","BIKE"]'}/>
                      )}
                    />
                  </td>
                  <td>
                    <Field
                      name="trip_id"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Enter Trip Id"}/>
                      )}
                    />
                  </td>
                  <td>
                    <Field
                      name="source_device"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Enter Source Device"}/>
                      )}
                    />
                  </td>
                  <td>
                    <Field
                      name="source_device_meta"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Enter Source Device Meta"}/>
                      )}
                    />
                  </td>
                  <td>
                    <Field
                      name="created_at"
                      render={({field, meta}) => (
                        <div className={"position-relative"}>
                          <Flatpickr
                            className={"c-date-picker form-control py-1"}
                            style={{fontSize: "11.375px"}}
                            placeholder={"Enter Date"} {...field}

                            onChange={e => {
                              setFieldValue('start_date', e[0].getTime())
                              try {
                                setFieldValue('end_date', e[1].getTime())
                              } catch (e) {

                              }
                            }}
                            options={{
                              dateFormat: "j/m/Y",
                              enableTime: true,
                              mode: "range"
                            }}/>
                          <button
                            className={"btn btn-sm btn-primary created-at-btn position-absolute"}
                            onClick={() => formik.submitForm()}>OK
                          </button>
                        </div>
                      )}
                    />
                  </td>
                </tr>
                {Object.keys(detectedObjects).map(key => {
                  let image_data = trip_images.find(img => convertUTCDateToLocalDate(img?.created_at) == convertUTCDateToLocalDate(detectedObjects[key]?.created_at))
                  return(
                    <tr key={`DOBJ-${key}-${detectedObjects[key]["id"]}`} className={"show-hand"} onClick={async () => {
                      await fetchGet(config.API_URL + `/api/v1/objects/tracking-path?trip_id=${detectedObjects[key]["trip_id"]}`).then(res => {
                        if (res.success) {
                          setTrackingPath(res.data["trackingPath"]);
                          setGeofencingData(res.data["tripGeofencing"]);
                          setShowModel(true);
                          setCurrentDetectedObject(detectedObjects[key]);
                        }
                      }).catch(err => {
                      })
                    }}>
                      <th scope="row">{detectedObjects[key]["id"]}</th>
                      <td>
                        <div
                          className={"color-box"}
                          style={{
                            backgroundColor:
                              config.COLLISION_STATE_COLOR_CODE[
                                detectedObjects[key]["color_code"]
                                ]
                          }}
                        />
                        {
                          config.COLLISION_STATE[
                            parseInt(detectedObjects[key]["color_code"])
                            ]
                        }
                      </td>
                      <td className={"zoomin"}>
                        {image_data ? 
                          <div>
                            <img src={image_data?.path} className={"icon-img"}/>
                            <div>
                              {image_data?.created_at}
                            </div>
                          </div>
                        : "No Ride Image Available"}
                      </td>
                      <td>
                        {detectedObjects[key]["gps"]["lat"]},{" "}
                        {detectedObjects[key]["gps"]["long"]}
                      </td>
                      <td>{detectedObjects[key]["objects"].join(", ")}</td>
                      <td>{detectedObjects[key]["trip_id"]}</td>
                      <td>{detectedObjects[key]["source_device"]}</td>
                      <td>{detectedObjects[key]["source_device_meta"]}</td>
                      <td>{convertUTCDateToLocalDate(detectedObjects[key]["created_at"])}</td>
                    </tr>
                  )}
                )}
                </tbody>
              </table>
              <input className={"d-none"} type={"submit"}/>
            </Form>
          )}>
        </Formik>
      </div>
      <PaginationBar totPages={totalPage} currentPage={page} pageClicked={(pageNumber) => {
        setPage(pageNumber);
      }}/> */}
      <br/>
      <div>
        <table id="example" className="display" style={{"width":"100%"}}>
          <thead>
            <tr>
              <th style={{maxWidth: '50px'}}>ID</th>
              <th style={{maxWidth: '120px'}}>Color Code</th>
              <th style={{maxWidth: '50px'}}>Media</th>
              <th style={{maxWidth: '250px'}}>GPS</th>
              <th style={{maxWidth: '300px'}}>Objects</th>
              <th style={{maxWidth: '80px'}}>Trip ID</th>
              <th style={{maxWidth: '100px'}}>Source Device</th>
              <th style={{maxWidth: '100px'}}>Source Device Meta</th>
              <th style={{maxWidth: '50px'}}>Created At</th>
            </tr>
          </thead>
          <tbody>
          {Object.keys(detectedObjects).map(key => {
                  let image_data = trip_images.find(img => (
                        convertUTCDateToLocalDate(img?.created_at) == convertUTCDateToLocalDate(detectedObjects[key]?.created_at)
                      ) 
                    || 
                      (
                        convertUTCDateToLocalDate(img?.created_at) > convertUTCDateToLocalDate(detectedObjects[key]?.created_at)
                      ))
                  return(
                    <tr key={`DOBJ-${key}-${detectedObjects[key]["id"]}`} className={"show-hand"} onClick={async () => {
                      await fetchGet(config.API_URL + `/api/v1/objects/tracking-path?trip_id=${detectedObjects[key]["trip_id"]}`).then(res => {
                        if (res.success) {
                          setTrackingPath(res.data["trackingPath"]);
                          setGeofencingData(res.data["tripGeofencing"]);
                          setShowModel(true);
                          setCurrentDetectedObject(detectedObjects[key]);
                        }
                      }).catch(err => {
                      })
                    }}>
                      <th scope="row">{detectedObjects[key]["id"]}</th>
                      <td>
                        <div
                          className={"color-box"}
                          style={{
                            backgroundColor: config.COLLISION_STATE_COLOR_CODE[detectedObjects[key]["color_code"]]
                          }}
                        />
                        {
                          config.COLLISION_STATE[parseInt(detectedObjects[key]["color_code"])]
                        }
                      </td>
                      {/* <td className={"zoomin"}>
                        {image_data ? 
                          <div>
                            <img src={image_data?.path} className={"icon-img"}/>
                          </div>
                        : "No Ride Image Available"}
                      </td> */}
                      <td>
                        {image_data ? 
                          <OverlayTrigger
                            key={detectedObjects[key]["id"]}
                            placement="right"
                            overlay={
                              <Popover id={`popover-positioned-${detectedObjects[key]["id"]}`} style={{zIndex: '99'}}>
                                  <div>
                                    <img alt={detectedObjects[key]["id"]} src={image_data?.path} style={{height: '100%', width: '100%'}}/>
                                  </div>
                                </Popover>
                              }
                              >
                              <img alt={detectedObjects[key]["id"]} src={image_data?.path} className={"icon-img"}/>
                          </OverlayTrigger>
                          : ""}
                      </td>
                      <td>
                        {detectedObjects[key]["gps"]["lat"]},{" "}
                        {detectedObjects[key]["gps"]["long"]}
                      </td>
                      <td>{detectedObjects[key]["objects"].join(", ")}</td>
                      <td>{detectedObjects[key]["trip_id"]}</td>
                      <td>{detectedObjects[key]["source_device"]}</td>
                      <td>{detectedObjects[key]["source_device_meta"]}</td>
                      <td>{convertUTCDateToLocalDate(detectedObjects[key]["created_at"])}</td>
                    </tr>
                  )}
                )}
          </tbody>
        </table>
      </div>
      <br/>
      {showModel ? 
        <Modal show={showModel} onHide={() => (setShowModel(false), changeSnapedStatus(true))}>
          <Modal.Header closeButton>
            <Modal.Title style={{fontSize: '16px'}}><b>{location_data.countryName+', '+location_data.city} | {convertUTCDateToLocalDate(currentDetectedObject["created_at"])} | {currentDetectedObject.source_device}</b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div>
                SNAP DATA POINTS &nbsp;&nbsp;
                <FormCheck 
                  type="switch"
                  inline={true}
                  id="custom-switch"
                  checked={snapedStatus}
                  onChange={() => changeSnapedStatus(!snapedStatus)}
                />
              </div>
              {showModel ?
              <PolygonMap
                snapedView={snapedStatus}
                gps={trackingPath}
                geofencingData={geofencingData}
              />: ""}
          </Modal.Body>
        </Modal>
      : null}
      {showGraphModel ? 
        <RideSummaryGraph showGraphModel={showGraphModel} setGraphShowModel={setGraphShowModel} totalItemCount={totalItemCount} trip_id={tripDashboardRedirect}/>
      : ''}
    </div>
  );
}

export default Dashboard;
