import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form} from "formik";
import {useHistory} from "react-router-dom";
import * as YUP from "yup";
import {InputField} from "./common/InputField";
import {Card, Col, Button, Modal} from "react-bootstrap";
import {fetchPost, fetchUploadFile, queryFetchGet} from "../utils/index";
import {config} from "../data/constants";
import {ButtonLoader} from "./common/ButtonLoader";
import { useQuery } from 'react-query';

const AddOperator = (props) => {
  const history = useHistory();
  let formikRef = useRef();

  const appUrl = config.API_URL + "/api/v1/voiceii/app-key";
  const userUrl = config.API_URL + "/api/v1/user/";

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationRules = YUP.object({
    first_name: YUP.string().required("First Name is required."),
    email: YUP.string().required("Email is required."),
    password: YUP.string().required("Password is required."),
  });

  const postData = data => {
    setIsSubmitting(true);
    fetchPost(userUrl, data).then(res => {
      if (res.success) {
        setIsSubmitting(false);
        history.push(`/operators`);
      }else{
        setIsSubmitting(false);
        alert("Something went wrong.");
      }
    }).catch(err => {
      console.log('Edit Operator Error: ', err)
    });
  }

  return (
    <>
      <section>
        <div className={"container-fluid"}>
          <div className={"row justify-content-start d-flex"}>
            <Col sm={6} md={6} lg={4}>
              <Card>
                <Card.Header>Add Operator</Card.Header>
                <Card.Body>
                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      role: 'operator'
                    }}
                    onSubmit={values => postData(values)}
                    validationSchema={validationRules}
                    innerRef={p => {
                      formikRef = p
                    }}>
                    {formik => (
                      <Form>
                        <InputField label={"First Name"} type={"text"} name={"first_name"} placeholder={"Enter the first name"}/>
                        <InputField label={"Last Name"} type={"text"} name={"last_name"} placeholder={"Enter the last name"}/>
                        <InputField label={"Email"} type={"text"} name={"email"} placeholder={"Enter the email"}/>
                        <InputField autocomplete="new-password" label={"Password"} type={"password"} name={"password"} placeholder={"Enter the password"}/>
                        <InputField label={null} type={"text"} name={"role"} className={"d-none"}/>
                        <ButtonLoader
                          type={"submit"} title={"Add"}
                          classes={"btn btn-sm btn-primary mt-2"}
                          loading={isSubmitting}/>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddOperator;