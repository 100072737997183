import React, {useContext, useEffect, useRef, useState} from "react";
import {config} from "../data/constants";
import {fetchDelete, fetchGet, fetchPut, queryFetchGet} from "../utils";
import { Button, Card, Container, Modal, Table } from "react-bootstrap";
import {Link} from "react-router-dom";
import * as YUP from "yup";
import {Form, Formik} from "formik";
import {InputField} from "./common/InputField";
import {ButtonLoader} from "./common/ButtonLoader";
import Lodash from "lodash";
import {UserContext} from "../router/AppRouter";
import BackdropLoader from "./common/BackdropLoader";
import { useQuery } from "react-query";
import DropdownField from "./common/DropdownField";
import { number } from "prop-types";
import $ from "jquery";
import DataTable from 'datatables.net';

const ManageAdmin = (props) => {
  const userContext = useContext((UserContext));
  let editAdminFormikRef = useRef();

  const validationRules = YUP.object({
      id: YUP.number().required("Id is required."),
      first_name: YUP.string().required("First Name is required."),
      email: YUP.string().required("Email is required."),
      password: YUP.string().required("Password is required."),
      operator: YUP.string().required("Operator is required."),
  });

  const userUrl = config.API_URL + "/api/v1/user/";
  const operatorUrl = config.API_URL + "/api/v1/user/operators";
  const adminUrl = config.API_URL + "/api/v1/user/admins";

  const [operatorId, setOperatorId] = useState();
  const [filterOperatorId, setFilterOperatorId] = useState('');
  const [showEditAdminModel, setShowEditAdminModel] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [operatorList, setOperators] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [tableData, setTableData] = useState(false); 

  //this code will remove the alert thrown by datatable
  $.fn.dataTable.ext.errMode = 'none';

  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": props.location.pathname}});
  }, []);

  const fetchData = async (url) => {
      let response = await queryFetchGet(url)
      return await response;
  }

  const fetchAdminData = async (url, _operator_id, _filterOperatorId) => {
      let operatorIdString = "";
      if (_operator_id && _operator_id.length > 0) {
        operatorIdString = `?operator_id=${JSON.stringify(_operator_id)}`
      }

      if(_filterOperatorId && _filterOperatorId != 'all'){
        operatorIdString = `?operator_id=${JSON.stringify([_filterOperatorId])}`
      }
      let response = await queryFetchGet(url+operatorIdString)
      return await response;
  }

  const operatorReq = useQuery('manageOperatorData', () => fetchData(operatorUrl), {enabled: false})
  const adminReq = useQuery(['manageAdminData', userContext.state?.operator_id, filterOperatorId], () => fetchAdminData(adminUrl, userContext.state?.operator_id, filterOperatorId), {enabled: false})
  
  useEffect(() => {
      operatorReq.refetch();
      adminReq.refetch();
  }, [userContext.state?.operator_id]);

  useEffect(() => {
      if (operatorReq.data && operatorReq.data.data) {
        let operatorList = operatorReq.data.data.operatorList
        let _operators = [];
        for (let i = 0; i < operatorList.length; i++) {
          _operators.push({value: operatorList[i]["id"], title: operatorList[i]["first_name"]+operatorList[i]["last_name"]})
        }
        setOperators(_operators);
        $('#operator_table').DataTable().destroy();
        setTimeout(() => {
          initialDatatable();
        }, 0);
      }
  }, [operatorReq.data]) 

  useEffect(() => {
      if(adminReq.data){
          setAdmins(adminReq.data.data["adminList"]);
      }
  }, [adminReq.data, admins])

  const initialDatatable = () => {
    let table = $('#operator_table').DataTable({
      paging: false,
      "aaSorting": []
    });

    setTableData(table);
  }

  useEffect(() => {
    adminReq.refetch();
  }, [filterOperatorId])

  const editAdmin = (values) => {
    values.operator = [values.operator]
    fetchPut(userUrl, values).then(res => {
      if (res.success) {
        setIsSubmitting(false);
        editAdminFormikRef.resetForm();
        setShowEditAdminModel(false);
        adminReq.refetch();
      }else{
        setIsSubmitting(false);
        alert("Something went wrong.");
      }
    }).catch(err => {
      console.log('Edit Admin Error: ', err)
    });
  }

  return (
    <>
      <BackdropLoader showLoader={operatorReq.isLoading}/>
      <section>
        <Container fluid={true}>
          <div className={"justify-content-between d-flex"}>
            <div className={"h2"}>Admin</div>
            {userContext?.state?.user_role != 'super_admin' ?
              <div className={"align-items-center d-flex"}>
                <Link className={"btn btn-sm btn-primary mx-1 fs-11-375px"} to={`/add-admin`}>
                  Add
                </Link>
              </div>
            : null}
          </div>
          {/* <br/>
          <Card>
            <Table hover responsive size="sm" className={"mb-0"}>
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <th>Operator</th>
                : null}
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td></td>
                <td></td>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <td>
                    <select name="operators_select" id="operator-select" onChange={(e) => setFilterOperatorId(e.target.value)}>
                      <option value="all">All</option>
                      {operatorList.map(d => {
                        return(
                          <option value={d.value}>{d.title}</option>
                        )
                      })}
                    </select>
                    </td>
                  : <td></td>
                }
                <td></td>
              </tr>
              {(admins && admins.length > 0) ? admins.map((admin, index) => (
                <tr key={index}>
                  <td>
                    <div>{admin?.first_name+ ' '+(admin?.last_name || "")}</div>
                  </td>
                  <td>
                    <div>{admin?.email}</div>
                  </td>
                  {userContext?.state?.user_role === 'super_admin' ? 
                    <td>
                      <div>{operatorList.find(op => op.value == admin.operator[0])?.title}</div>
                    </td>
                  : null}
                  <td>
                    <Button variant={"warning"} size={"sm"} className={"mx-2 fs-11-375px"} onClick={() => {
                        setOperatorId(admin?.operator[0]);
                        editAdminFormikRef.setFieldValue("operator", admin?.operator[0])
                        editAdminFormikRef.setFieldValue("id", admin["id"]);
                        editAdminFormikRef.setFieldValue("first_name", admin["first_name"]);
                        editAdminFormikRef.setFieldValue("last_name", (admin?.last_name || ''));
                        editAdminFormikRef.setFieldValue("last_name", admin["last_name"]);
                        editAdminFormikRef.setFieldValue("email", admin["email"]);
                        editAdminFormikRef.setFieldValue("password", admin["password"]);
                        editAdminFormikRef.setFieldValue("role", admin["role"]);
                        setShowEditAdminModel(true);    
                    }
                    }>Edit</Button>
                  </td>
                </tr>)) : ""}
              </tbody>
            </Table>
          </Card> */}
        </Container>
        <div style={{padding: "0px 10px"}}>
          <table id="operator_table" className="display" style={{"width":"100%"}}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <th>Operator</th>
                : <th style={{display: "none"}}/>}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {(admins && admins.length > 0) ? admins.map((admin, index) => (
              <tr key={index}>
                <td>
                  <div>{admin?.first_name+ ' '+(admin?.last_name || "")}</div>
                </td>
                <td>
                  <div>{admin?.email}</div>
                </td>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <td>
                    <div>{operatorList.find(op => op.value == admin.operator[0])?.title}</div>
                  </td>
                : <td style={{display: "none"}}/>}
                <td>
                  <Button variant={"warning"} size={"sm"} className={"mx-2 fs-11-375px"} onClick={() => {
                      setOperatorId(admin?.operator[0]);
                      editAdminFormikRef.setFieldValue("operator", admin?.operator[0])
                      editAdminFormikRef.setFieldValue("id", admin["id"]);
                      editAdminFormikRef.setFieldValue("first_name", admin["first_name"]);
                      editAdminFormikRef.setFieldValue("last_name", (admin?.last_name || ''));
                      editAdminFormikRef.setFieldValue("last_name", admin["last_name"]);
                      editAdminFormikRef.setFieldValue("email", admin["email"]);
                      editAdminFormikRef.setFieldValue("password", admin["password"]);
                      editAdminFormikRef.setFieldValue("role", admin["role"]);
                      setShowEditAdminModel(true);    
                  }
                  }>Edit</Button>
                </td>
              </tr>)) : ""}
            </tbody>
          </table>
        </div>
        <br/>
        <Formik
          initialValues={{
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role: "admin",
            operator: ""
          }}
          onSubmit={values => {
            editAdmin(values);
          }}
          innerRef={(p) => {
            editAdminFormikRef = p
          }}
          validationSchema={validationRules}>
          {formik => (
            <Modal show={showEditAdminModel}>
              <Form>
                <Modal.Header>
                  <Modal.Title>Edit Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"pt-0"}>
                    <InputField label={null} type={"text"} name={"id"} className={"d-none"}/>
                    <InputField label={"First Name"} type={"text"} name={"first_name"} placeholder={"Enter the first name"}/>
                    <InputField label={"Last Name"} type={"text"} name={"last_name"} placeholder={"Enter the last name"}/>
                    <InputField label={"Email"} type={"text"} name={"email"} placeholder={"Enter the email"}/>
                    <InputField autocomplete="new-password" label={"Password"} type={"password"} name={"password"} placeholder={"Enter the password"}/>
                    <InputField label={null} type={"text"} name={"role"} className={"d-none"}/>
                    <div className={"d-none"}>
                      <DropdownField
                          label={"Operator"} name={"operator"} placeholder={"Select a Operator"} options={operatorList}
                          disabled={true}
                          selectedValue={operatorId} initialOption={"Select a Operator"}
                          handleChange={(event) => {
                              formik.setFieldValue("operator", event.target.value);
                          }}
                          onOptionChange={(event => {
              
                          })}
                      />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size={"sm"} className={"mt-2 fs-11-375px"} 
                        onClick={() => {
                            setShowEditAdminModel(false);
                            formik.resetForm();
                        }}
                    >
                    Cancel
                  </Button>
                  <ButtonLoader type={"submit"} title={"Update"} classes={"btn btn-sm btn-primary mt-2 fs-11-375px"} loading={isSubmitting}/>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </Formik>
      </section>
    </>
  );
}
export default ManageAdmin;