import React, {useContext, useEffect, useRef, useState} from 'react';
import {Formik, Form} from "formik";
import {useHistory} from "react-router-dom";
import * as YUP from "yup";
import {InputField} from "./common/InputField";
import {Card, Col, Button, Modal} from "react-bootstrap";
import {fetchPost, fetchUploadFile, queryFetchGet} from "../utils/index";
import {config} from "../data/constants";
import {ButtonLoader} from "./common/ButtonLoader";
import DropdownField from "./common/DropdownField"
import { useQuery } from 'react-query';
import { UserContext } from '../router/AppRouter';

const AddUser = (props) => {
  const userContext = useContext((UserContext));
  const history = useHistory();
  let formikRef = useRef();

  const [operatorId, setOperatorId] = useState();
  const [operatorList, setOperatorList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const operatorUrl = config.API_URL + "/api/v1/user/operators";
  const userUrl = config.API_URL + "/api/v1/user/";

  const validationRules = YUP.object({
    first_name: YUP.string().required("First Name is required."),
    email: YUP.string().required("Email is required."),
    password: YUP.string().required("Password is required."),
    operator: YUP.string().required("Operator is required."),
  });

  const fetchData = async (url) => {
    let response = await queryFetchGet(url)
    return await response;
  }

  const operatorReq = useQuery('OperatorData', () => fetchData(operatorUrl), {enabled: false})

  useEffect(() => {
    operatorReq.refetch();
  }, []);

  useEffect(() => {
    if(userContext.state?.operator_id){
      setOperatorId(userContext.state?.operator_id[0]);
      formikRef.setFieldValue("operator", userContext.state?.operator_id[0])
    }
  }, [userContext.state?.operator_id])

  useEffect(() => {
    if (operatorReq.data && operatorReq.data.data) {
      let operatorList = operatorReq.data.data.operatorList
      let _operators = [];
      for (let i = 0; i < operatorList.length; i++) {
        _operators.push({value: operatorList[i]["id"], title: operatorList[i]["first_name"]+operatorList[i]["last_name"]})
      }
      setOperatorList(_operators);
    }
  }, [operatorReq.data])

  const postData = data => {
    data.operator = [data.operator]
    setIsSubmitting(true);

    fetchPost(userUrl, data).then(res => {
      if (res.success) {
        setIsSubmitting(false);
        history.push(`/users`);
      }else{
        setIsSubmitting(false);
        alert("Something went wrong.");
      }
    }).catch(err => {
        console.log('Edit User Error: ', err)
    });
  }

  return (
    <>
      <section>
        <div className={"container-fluid"}>
          <div className={"row justify-content-start d-flex"}>
            <Col sm={6} md={6} lg={4}>
              <Card>
                <Card.Header>Add User</Card.Header>
                <Card.Body>
                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      role: 'user',
                      operator: ""
                    }}
                    onSubmit={values => postData(values)}
                    validationSchema={validationRules}
                    innerRef={p => {
                      formikRef = p
                    }}>
                    {formik => (
                      <Form>
                        <InputField label={"First Name"} type={"text"} name={"first_name"} placeholder={"Enter the first name"}/>
                        <InputField label={"Last Name"} type={"text"} name={"last_name"} placeholder={"Enter the last name"}/>
                        <InputField label={"Email"} type={"text"} name={"email"} placeholder={"Enter the email"}/>
                        <InputField autocomplete="new-password" label={"Password"} type={"password"} name={"password"} placeholder={"Enter the password"}/>
                        <InputField label={null} type={"text"} name={"role"} className={"d-none"}/>
                        <div className={"d-none"}>
                          <DropdownField
                            label={"Operator"} name={"operator"} placeholder={"Select a Operator"} options={operatorList}
                            selectedValue={operatorId} initialOption={"Select a Operator"}
                            handleChange={(event) => {
                              formik.setFieldValue("operator", event.target.value);
                            }}
                            onOptionChange={(event => {
                              //formik.setFieldValue("operator_id", event.target.value);
                            })}
                          />
                        </div>
                        <ButtonLoader
                          type={"submit"} title={"Add"}
                          classes={"btn btn-sm btn-primary mt-2"}
                          loading={isSubmitting}/>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddUser;