import {ErrorMessage, useField} from "formik";
import React from "react";

export const InputField = ({label, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <div className={"form-row"}>
            <div className={"my-3"}>
                {label ? <div className={"mb-1"}>{label}</div> : ""}
                <input
                    className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`}
                    autoComplete={props.autoComplete}
                    {...field} {...props}
                />
                <ErrorMessage name={field.name} className={"error-message"} component={"div"}/>
            </div>
        </div>
    );
}