import React, {useContext, useEffect, useRef, useState} from 'react';
import {Formik, Form} from "formik";
import * as YUP from "yup";
import {InputField} from "./common/InputField";
import {Card, Col, Button, Modal, ButtonGroup} from "react-bootstrap";
import {fetchPost, queryFetchGet} from "../utils/index";
import {config} from "../data/constants";
import { useQuery } from 'react-query';
import { UserContext } from '../router/AppRouter';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import { DrawingManager, Polygon } from '@react-google-maps/api';
import BackdropLoader from "./common/BackdropLoader";

const containerStyle = {
    width: '100%',
    height: '600px'
  };

const OperatorSettings = (props) => {
  const userContext = useContext((UserContext));
  
  let formikRef = useRef();
  let newRef = useRef();
  const validationRules = YUP.object({});
  
  const settingsUrl = config.API_URL + "/api/v1/operator-settings";
  
  let {isLoaded} = useJsApiLoader({
      id: 'drawing-manager-example',
      googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
      libraries: ["drawing"]
  });

  const fetchData = async (url, _operator_id) => {
    console.log('as12dasassd', _operator_id[0])
    let response = await queryFetchGet(url+'?operator_id='+_operator_id)
    return await response;
  }
  
  const [mapData, setMapData] = useState([]);
  const [speedLimit, setSpeedLimit] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentCenterPoint, changeCurrentCenterPoint] = useState({lat: 55.531315561060296, lng: -115.13183803017255}) 
  const [currentCenterIndex, changeCurrentCenterIndex] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false)
  const [modalId, setModalId] = useState('')
  
  const operatorSettingsReq = useQuery(['operatorSettings', userContext.state?.operator_id], () => fetchData(settingsUrl, userContext.state?.operator_id))
  
  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": props.location.pathname}});
  }, [])

  useEffect(() => {
    if(operatorSettingsReq.data){
      let findMapData = operatorSettingsReq?.data?.data.find(d => d.key == 'map_data');
      if(findMapData){
        setMapData(findMapData.value)
        changeCurrentCenterPoint(findMapData.value[0].map_data[0]);
      }
    }
  }, [operatorSettingsReq.data])
    
  const onPolygonComplete = polygon => {
    if(newRef.current.drawingMode){
      setMapData([...mapData, {
        map_data: polygon.getPath().getArray().map((coord) => {return {lat: coord.lat(),lng: coord.lng()}}),
        speed_limit: ''
      }])
    }
    polygon.setMap(null);
    newRef.current.setDrawingMode(null);
  }

  const mapClicked = (i) => {
    setMapData(mapData.map((d, index) => index != i ? d : null).filter(d => d))
  } 

  const postData = () => {
    setIsSubmitting(true);

    let data = {
      settingsData: [
        {
          'key': 'map_data',
          'value': mapData.length ? mapData : []
        }
      ],
      operator_id: userContext.state?.operator_id[0]
    }

    fetchPost(settingsUrl, {...data}).then(res => {
      setIsSubmitting(false);
    }).catch(err => {
      setIsSubmitting(false);
      console.log('Error: ', err)
    });
  }

  const handlerLoadDrawingManager = (drawingManagerInstance) => {
    newRef.current = drawingManagerInstance
  }

  const changeCenterPoint = (status) => {
    let dataLength = mapData.length - 1;
    if(dataLength != -1){
      if(status == 'up'){
        let currentIndex = currentCenterIndex < dataLength ? currentCenterIndex + 1 : currentCenterIndex;
        changeCurrentCenterIndex(currentIndex);
        changeCurrentCenterPoint(mapData ? center_polygon(mapData[currentIndex].map_data) : '')
      }else{
        let currentIndex = currentCenterIndex > 0 ? currentCenterIndex - 1 : currentCenterIndex;
        changeCurrentCenterIndex(currentIndex);
        changeCurrentCenterPoint(mapData ? center_polygon(mapData[currentIndex].map_data) : '')
      }
    }
  }

  const changeSpeedLimitData = (value, id) => {
    let newMapData = mapData.map((d, i) => {
      d.speed_limit = i == id ? value : (d?.speed_limit || '')
      return d
    })
    setMapData(newMapData)
  }

  const center_polygon = (coordinates) => {
    let x = coordinates.map(c => c.lat);
    let y = coordinates.map(c => c.lng);

    let minX = Math.min.apply(null, x);
    let maxX = Math.max.apply(null, x);

    let minY = Math.min.apply(null, y);
    let maxY = Math.max.apply(null, y);

    return {
      lat: (minX + maxX) / 2,
      lng: (minY + maxY) / 2
    };
  }
  
  return (
    <>
      {userContext?.state?.user_role == 'operator' ? 
        <section>
        <BackdropLoader showLoader={isSubmitting}/>
        <Card className="m-2" border="light">
          <Card.Header>Operator Settings</Card.Header>
          <Card.Body>
            <Card className="m-2">
              <Card.Header>Speed Limit Settings</Card.Header>
              <Card.Body>
                <Card.Text>
                  <div className="mb-2">
                    <span>
                      <Button onClick={() => newRef.current.setDrawingMode(null)}>Drag</Button>&nbsp;&nbsp;
                      <Button onClick={() => newRef.current.setDrawingMode('polygon')}>Create <i class="bi bi-plus-lg"></i></Button>
                    </span>
                    <ButtonGroup className="mx-2">
                      <Button onClick={() => changeCenterPoint('down')}>{'<'}</Button>
                      <Button onClick={() => changeCenterPoint('up')}>{'>'}</Button>
                    </ButtonGroup>
                  </div>
                  <div className={"row justify-content-start d-flex"}>
                    <div>
                      {isLoaded && operatorSettingsReq.data && mapData ? 
                        <GoogleMap
                          id="drawing-manager-example"
                          mapContainerStyle={containerStyle}
                          zoom={10}
                          center={currentCenterPoint}
                        >
                          <DrawingManager
                            ref={newRef}
                            options={{
                                drawingControl: false,
                                drawingControlOptions: {
                                drawingModes: ['polygon']
                                },
                                polygonOptions: { editable: true }
                            }}
                            onLoad={handlerLoadDrawingManager}
                            onPolygonComplete={onPolygonComplete}
                          />
                          {mapData ? 
                            mapData.map((d, i) => {
                              return (
                                <Polygon
                                  draggable={false}
                                  editable={false}
                                  options={{
                                    strokeColor: '#FF0000',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: '#FF0000',
                                    fillOpacity: 0.35,
                                  }}
                                  onClick={(e) => {
                                    setModalId(i);
                                    setDeleteModal(true); 
                                    setSpeedLimit(d.speed_limit);
                                  }}
                                  clickable
                                  path={d.map_data}
                                />
                              )
                            })
                          : null}
                          {mapData ? 
                            mapData.map((d, i) => {
                              return(
                                <Marker
                                  key={i}
                                  label={{text:"Speed Limit: "+ d?.speed_limit || 0, color:'#fff', fontSize: '18px'}}
                                  position={center_polygon(d.map_data)}
                                />
                              )
                            })
                          : null}
                        </GoogleMap>  
                        : null}
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
              <div className="m-2">
                <Button onClick={() => postData()}>Save Settings</Button>
              </div>
          </Card.Body>
        </Card>
        <Modal show={deleteModal} onHide={() => { setSpeedLimit(); setDeleteModal(false); setModalId('') }}>
          <Modal.Header closeButton>Other Data</Modal.Header>
          <Modal.Body>
            {console.log('propss', speedLimit, mapData[modalId]?.speed_limit)}
            <div>
              <Formik
                validationSchema={validationRules}
                innerRef={p => { formikRef = p }}>
                {formik => (
                  <Form>
                    <div>
                      <Col sm={4} className='my-3'>
                        <InputField 
                          key={modalId}
                          label={"Speed Limit"} 
                          type={"number"} 
                          name={"speed_limit"} 
                          min={1}
                          defaultValue={mapData[modalId]?.speed_limit}
                          value={speedLimit}
                          placeholder={"Set Speed Limit"}
                          onChange={(e) => setSpeedLimit(e.target.value > 0 ? e.target.value : 1)}
                        />
                      </Col>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size={"sm"} className={"mt-2 fs-11-375px"} onClick={() => { setDeleteModal(false); setModalId('') }}>
              Cancel
            </Button>
            <Button className={"mt-2 fs-11-375px"} variant="danger" size={"sm"} onClick={() => 
              {
                setSpeedLimit();
                mapClicked(modalId);
                setDeleteModal(false);
                setModalId('')
              }
            }>
              Delete
            </Button>
            <Button className={"mt-2 fs-11-375px"} size={"sm"} onClick={() => 
              {
                setSpeedLimit();
                changeSpeedLimitData(speedLimit, modalId)
                setDeleteModal(false);
                setModalId('')
              }
            }>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        </section>
      : null}
    </>
  );
};

export default OperatorSettings;