const {getConfig} = require("../config");
let _config = {
  cookieDomain: "",
  cookieExpiryDays: 60,
  JWT_SECRET: "BsOHifo50O",
  message: {
    GENERIC_SUCCESS_MESSAGE: "Updated successfully.",
    GENERIC_ERROR_MESSAGE: "There was an error while updating."
  },
  HTTP_STATUS_CODE: {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORISED: 401,
    INTERNAL_SERVER_ERROR: 500
  },
  COLLISION_STATE: {
    1: "SAFE",
    2: "CAUTION",
    3: "WARNING",
    4: "DANGER",
    5: "EXTREME_DANGER"
  },
  COLLISION_STATE_COLOR_CODE: {
    1: "#00BC00",
    2: "#FFFF00",
    3: "#FBB800",
    4: "#FF9896",
    5: "#EB0000"
  },
  DEFAULT_PAGE_SIZE: 15,

  GOOGLE_MAPS_API_KEY: "AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac"
};
let config = Object.assign({}, _config, getConfig());

module.exports = {
  config: config
};
