import React, {useEffect, useRef, useState, useContext} from "react";
import {config} from "../data/constants";
import {fetchGet, queryFetchGet} from "../utils/index";
import {useHistory} from "react-router-dom";
import {Formik, Form, Field} from "formik";
import "flatpickr/dist/flatpickr.min.css";
import upDownArrow from "../static/images/up-down-arrow.svg";
import upArrow from "../static/images/up-arrow.svg";
import downArrow from "../static/images/down-arrow.svg";
import {Modal, Button, FormCheck, OverlayTrigger, Tooltip} from "react-bootstrap";
import PolygonMap from "./PolygonMap";
import {UserContext} from "../router/AppRouter";
import DropdownField from "./common/DropdownField";
import BackdropLoading from "./common/BackdropLoader";
import PaginationBar from "./common/PaginationBar";
import { useQuery } from 'react-query';
import $ from "jquery";
import DataTable from 'datatables.net';

function TripDashboard(props) {
  const history = useHistory();
  const userContext = useContext(UserContext);

  const [tripDetails, setTripDetails] = useState({});
  const [totalPage, setTotalPage] = useState({});
  const [location_data, setLocationData] = useState({});
  const [currentDetectedObject, setCurrentDetectedObject] = useState({});
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [filter, setFilterBy] = useState({});
  const [trackingPath, setTrackingPath] = useState(null);
  const [geofencingData, setGeofencingData] = useState(null)
  const [showModel, setShowModel] = useState(null);
  const [allUsers, setAllUsers] = useState([{title: "All", value: 0}]);
  const [selectedUser, setSelectedUser] = useState(0);
  const [snapedStatus, changeSnapedStatus] = useState(true)
  const [tripDashboardRedirect, setTripDashboardRedirect] = useState(new URLSearchParams(props.location.search).get("user_id"));

  useEffect(() => {
    setTripDashboardRedirect(new URLSearchParams(props.location.search).get("user_id"));
  }, [props.location.search])

  let formik = useRef();
  const fetchUserTrips = async(_page, _filter = null, _sortBy = null, _operator_id, _user_id) => {
    let filterString = "";
    let sortByString = "";
    let operatorIdString = "";
    let userIdString = "";
    if (_filter) {
      for (let [key, value] of Object.entries(_filter)) {
        filterString += `&${key}=${value}`
      }
    }
    if (_sortBy) {
      sortByString = `&sort_by=${_sortBy}`
    }
    if (_operator_id && _operator_id.length > 0) {
      operatorIdString = `&operator_id=${JSON.stringify(_operator_id)}`
    }

    if(_user_id){
      userIdString = `&user_id=${_user_id}`
    }


    let url = config.API_URL + "/api/v1/objects/user-trips?" + filterString + sortByString + operatorIdString + userIdString;
    let response = await queryFetchGet(url);
    return await response;
  }

  const { isLoading, isError, data, refetch } = useQuery(['trip-dashboard', page, filter, sortBy, userContext?.state?.operator_id, tripDashboardRedirect], () => fetchUserTrips(page, filter, sortBy, userContext?.state?.operator_id, tripDashboardRedirect), {enabled: false});
  useEffect(()=> {
    if(data){
      $('#example').DataTable().destroy();
      setTimeout(() => {
        $('#example').DataTable({
          "pageLength": 20, 
          lengthMenu: [[5, 10, 20, 50], [5, 10, 20, 50]],
          "aaSorting": []
        });
      }, 0);
      setTripDetails({...data.data['trip_details']});
      setTotalPage(data.data['total_page']);
      if (allUsers.length === 1) {
        let _users = [{title: "All", value: 0}];
        let _userIds = [];
        for (let [key, val] of Object.entries(data.data['users'])) {
          if (!_userIds.includes(val["id"])) {
            _users.push({title: val["first_name"] + " " + val["last_name"], value: val["id"]});
            _userIds.push(val["id"]);
          }
        }
        setAllUsers(_users);
      }
    }
  },[data]);

  useEffect(() => {
      refetch();
  }, [tripDashboardRedirect])

  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": props.location.pathname}});
    refetch();
  }, [page, filter, sortBy, userContext.state?.operator_id]);

  // useEffect(() => {
  //   $('#example').DataTable();
  // }, [])

  function convertUTCDateToLocalDate(date) {
    return new Date(date).toLocaleString();
  }

  function calculateDuration(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const durationInMilliseconds = endDate - startDate;
    const durationInSeconds = durationInMilliseconds / 1000;
    const durationInMinutes = durationInSeconds / 60;
    const durationInHours = durationInMinutes / 60;

    const hours = Math.floor(durationInHours);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor(durationInSeconds % 60);

    return `${hours} hr ${minutes} min ${seconds} sec`;
  }

  function getCity(coordinates) {
    var xhr = new XMLHttpRequest();
    var lat = coordinates[0];
    var lng = coordinates[1];
  
    // Paste your LocationIQ token below.
    xhr.open('GET', `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`,true);
    xhr.send();
    xhr.onreadystatechange = processRequest;
    xhr.addEventListener("readystatechange", processRequest, false);
    
    function processRequest(e) {
        if (xhr.readyState == 4 && xhr.status == 200) {
          setLocationData(JSON.parse(xhr.responseText));
        }
    }
  }

  return (
    <div className={"container-fluid"}>
      <BackdropLoading showLoader={isLoading}/>
      {/* <div className={"d-flex justify-content-end my-2 align-items-center"}>
        <div className={""}>
          <button className={"btn btn-sm btn-primary"} onClick={() => {
            setSortBy(null);
            setFilterBy(null);
            formik.resetForm();
            setSelectedUser(0);
            setPage(1);
          }}>
            Reset
          </button>
        </div>
      </div>
      <div className="card">
        <Formik
          initialValues={{
            user_id: "",
            trip_id: "",
            source_device: "",
            source_device_meta: "",
          }}
          innerRef={p => {
            formik = p
          }}
          onSubmit={values => {
            setFilterBy(values);
            setPage(1);
          }}
          render={({
                     values,
                     handleSubmit,
                     handleChange,
                     setFieldValue
                   }) => (
            <Form>
              <table
                className={"table font-size-sm mb-0 table-condensed table-hover"}
              >
                <thead>
                <tr>
                  <th scope="col" className={"show-hand w-100px"}>Username</th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "trip_id-asc";
                    if (sortBy === me) {
                      setSortBy("trip_id-desc");
                      me = "trip_id-desc";
                    } else {
                      setSortBy("trip_id-asc");
                    }
                  }}>Trip ID<img alt={""}
                                 src={sortBy === "trip_id-asc" ? downArrow : sortBy === "trip_id-desc" ? upArrow : upDownArrow}
                                 className={"sort-arrow"}
                  /></th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "start_time-asc";
                    if (sortBy === me) {
                      setSortBy("start_time-desc");
                      me = "start_time-desc";
                    } else {
                      setSortBy("start_time-asc");
                    }
                  }}>Start Time<img alt={""}
                                    src={sortBy === "start_time-asc" ? downArrow : sortBy === "start_time-desc" ? upArrow : upDownArrow}
                                    className={"sort-arrow"}
                  /></th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "end_time-asc";
                    if (sortBy === me) {
                      setSortBy("end_time-desc");
                      me = "end_time-desc";
                    } else {
                      setSortBy("end_time-asc");
                    }
                  }}>End Time<img alt={""}
                                  src={sortBy === "end_time-asc" ? downArrow : sortBy === "end_time-desc" ? upArrow : upDownArrow}
                                  className={"sort-arrow"}
                  /></th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "source_device-asc";
                    if (sortBy === me) {
                      setSortBy("source_device-desc");
                      me = "source_device-desc";
                    } else {
                      setSortBy("source_device-asc");
                    }
                  }}>Source Device<img alt={""}
                                       src={sortBy === "source_device-asc" ? downArrow : sortBy === "source_device-desc" ? upArrow : upDownArrow}
                                       className={"sort-arrow"}
                  />
                  </th>
                  <th scope="col" className={"show-hand"} onClick={() => {
                    let me = "source_device_meta-asc";
                    if (sortBy === me) {
                      setSortBy("source_device_meta-desc");
                      me = "source_device_meta-desc";
                    } else {
                      setSortBy("source_device_meta-asc");
                    }
                  }}>Device App Version
                    <img alt={""}
                         src={sortBy === "source_device_meta-asc" ? downArrow : sortBy === "source_device_meta-desc" ? upArrow : upDownArrow}
                         className={"sort-arrow"}
                    />
                  </th>
                  <th>
                    Action
                  </th>
                  <th>
                    Final Result
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <div className={tripDashboardRedirect ? "d-none" : ""}>
                      <DropdownField
                        options={allUsers} selectedValue={selectedUser} label={null} initialOption={null} name={"user_id"}
                        handleChange={async (event) => {
                          setSelectedUser(event.target.value);
                          formik.setFieldValue("user_id", event.target.value);
                          await formik.submitForm();
                        }}
                        onOptionChange={(event) => {

                        }}/>
                    </div>
                  </td>
                  <td>
                    <Field
                      name="trip_id"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Enter Trip Id"}/>
                      )}
                    />
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                    <Field
                      name="source_device"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Enter Source Device"}/>
                      )}
                    />
                  </td>
                  <td>
                    <Field
                      name="source_device_meta"
                      render={({field, meta}) => (
                        <input
                          className={`form-control form-control-sm ${meta.touched && meta.error && 'is-invalid'}`} {...field}
                          placeholder={"Enter App version"}/>
                      )}
                    />
                  </td>
                  <td/>
                  <td/>
                </tr>
                {Object.keys(tripDetails).map(key => (
                  <tr key={`DOBJ-${key}-${tripDetails[key]["id"]}`}>
                    <td scope="row">{tripDetails[key]["name"]}</td>
                    <td>{tripDetails[key]["trip_id"]}</td>
                    <td>{convertUTCDateToLocalDate(tripDetails[key]["start_time"])}</td>
                    <td>{convertUTCDateToLocalDate(tripDetails[key]["end_time"])}</td>
                    <td>
                      {tripDetails[key]["source_device"]}
                    </td>
                    <td>{tripDetails[key]["source_device_meta"]}</td>
                    <td>
                      <Button variant={"secondary"} size={"sm"} onClick={async () => {
                        fetchGet(config.API_URL + `/api/v1/objects/tracking-path?trip_id=${tripDetails[key]["trip_id"]}`).then(res => {
                          if (res.success) {
                            getCity([res.data.trackingPath[0]?.lat, res.data.trackingPath[0]?.long])
                            setCurrentDetectedObject({tripData: tripDetails[key]});
                            setTrackingPath(res.data["trackingPath"]);
                            setGeofencingData(res.data["tripGeofencing"]);
                            setShowModel(true);
                          }
                        }).catch(err => {
                        })
                      }}>
                        Map
                      </Button>
                      <Button variant={"warning"} size={"sm"} className={"mx-2"} onClick={() => {
                        history.push(`/objects-detected?trip_id=${tripDetails[key]["trip_id"]}`);
                      }}>
                        Details
                      </Button>
                      {tripDetails[key]["trip_images"] ? 
                        <OverlayTrigger
                          key={"top"}
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Helmet Detection Images
                            </Tooltip>
                          }
                        >
                          <Button variant={"danger"} size={"sm"} className={"mx-2"} onClick={() => {
                            history.push(`/helmet-detection?trid_id=${tripDetails[key]["trip_id"]}`);
                          }}>
                            HD
                          </Button>
                        </OverlayTrigger>
                      : null}
                      {tripDetails[key]["trip_images"] ? 
                        <OverlayTrigger
                          key={"top"}
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Ride Media Images
                            </Tooltip>
                          }
                        >
                          <Button variant={"danger"} size={"sm"} className={"mx-2"} onClick={() => {
                            history.push(`/ride-media?trid_id=${tripDetails[key]["trip_id"]}`);
                          }}>
                            RM
                          </Button>
                        </OverlayTrigger>
                      : null}
                    </td>
                    {tripDetails[key]["final_result"] != null ? 
                      <td>{tripDetails[key]["final_result"] ? 'WITH HELMET' : 'WITHOUT HELMET'}</td>
                    : <td/>}
                  </tr>
                ))}
                </tbody>
              </table>
              <input className={"d-none"} type={"submit"}/>
            </Form>
          )}>
        </Formik>
      </div>
      <PaginationBar totPages={totalPage} currentPage={page} pageClicked={(pageNumber) => {
        setPage(pageNumber);
      }}/> */}
      <br/>
      <div>
        <table id="example" className="display" style={{"width":"100%"}}>
          <thead>
            <tr>
                <th>Username</th>
                <th>Trip ID</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
                <th>Source Device</th>
                <th>Device App Version</th>
                <th>Action</th>
                <th>Final Result</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(tripDetails).map(key => (
              <tr key={`DOBJ-${key}-${tripDetails[key]["id"]}`}>
                <td>{tripDetails[key]["name"]}</td>
                <td>{tripDetails[key]["trip_id"]}</td>
                <td>{convertUTCDateToLocalDate(tripDetails[key]["start_time"])}</td>
                <td>{convertUTCDateToLocalDate(tripDetails[key]["end_time"])}</td>
                <td>{calculateDuration(tripDetails[key]["start_time"], tripDetails[key]["end_time"])}</td>
                <td>{tripDetails[key]["source_device"]}</td>
                <td>{tripDetails[key]["source_device_meta"]}</td>
                <td className="d-flex">
                <Button variant={"secondary"} size={"sm"} onClick={async () => {
                  fetchGet(config.API_URL + `/api/v1/objects/tracking-path?trip_id=${tripDetails[key]["trip_id"]}`).then(res => {
                    if (res.success) {
                      getCity([res.data.trackingPath[0]?.lat, res.data.trackingPath[0]?.long])
                      setCurrentDetectedObject({tripData: tripDetails[key]});
                      setTrackingPath(res.data["trackingPath"]);
                      setGeofencingData(res.data["tripGeofencing"]);
                      setShowModel(true);
                    }
                  }).catch(err => {
                  })
                }}>
                  Map
                </Button>
                <Button variant={"warning"} size={"sm"} className={"mx-2"} onClick={() => {
                  history.push(`/objects-detected?trip_id=${tripDetails[key]["trip_id"]}`);
                }}>
                  Details
                </Button>
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Helmet Detection Images
                    </Tooltip>
                  }
                >
                  <Button variant={"danger"} size={"sm"} className={"mx-2"} onClick={() => {
                    history.push(`/helmet-detection?trid_id=${tripDetails[key]["trip_id"]}`);
                  }}>
                    HD
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Ride Media Images
                    </Tooltip>
                  }
                >
                  <Button variant={"danger"} size={"sm"} className={"mx-2"} onClick={() => {
                    history.push(`/ride-media?trid_id=${tripDetails[key]["trip_id"]}`);
                  }}>
                    RM
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Trip Media Data
                    </Tooltip>
                  }
                >
                  <Button variant={"danger"} size={"sm"} className={"mx-2"} onClick={() => {
                    history.push(`/media-player?trid_id=${tripDetails[key]["trip_id"]}`);
                  }}>
                    MP
                  </Button>
                </OverlayTrigger>
                </td>
                {tripDetails[key]["final_result"] != null ? 
                  <td>{tripDetails[key]["final_result"] ? 'WITH HELMET' : 'WITHOUT HELMET'}</td>
                : <td/>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br/>
      {showModel ? 
        <Modal show={showModel} onHide={() => (setShowModel(false), changeSnapedStatus(true))}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{location_data.countryName+', '+location_data.city} | {convertUTCDateToLocalDate(currentDetectedObject.tripData.start_time)} | {currentDetectedObject.tripData.source_device}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              SNAP DATA POINTS &nbsp;&nbsp;
              <FormCheck 
                type="switch"
                inline={true}
                id="custom-switch"
                checked={snapedStatus}
                onChange={() => changeSnapedStatus(!snapedStatus)}
              />
            </div>
            {showModel ?
              <PolygonMap
                snapedView={snapedStatus}
                geofencingData={geofencingData}
                gps={trackingPath}
              /> : ""}
          </Modal.Body>
        </Modal>
      : null}
    </div>
  );
}

export default TripDashboard;
