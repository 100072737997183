import {ErrorMessage, useField} from "formik";
import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";

const DropdownField = ({label, options, selectedValue, initialOption, handleChange, onOptionChange, ...props}) => {
  const [field, meta] = useField(props);
  const [defaultSelected, setDefaultSelected] = useState(selectedValue);

  useEffect(() => {
    if (onOptionChange && typeof (onOptionChange) === 'function') {
      let found = null;
      for (let option of options) {
        if (option.value === selectedValue) {
          found = option;
          break;
        }
      }
      if (found) {
        onOptionChange({target: {value: found.value}});
      } else {
        onOptionChange({target: {value: ""}});
      }
    }
  }, [options]);

  useEffect(() => {
    setDefaultSelected(selectedValue);
  }, [selectedValue]);
  return (
    <div className={"form-row"}>
      {label ? <div className={"mb-1"}>{label}</div> : ""}
      <Form.Select
        aria-label="Default select example" size={"sm"} {...field} {...props}
        className={`${meta.touched && meta.error && 'is-invalid'}`} value={defaultSelected}
        onChange={(event) => {
          setDefaultSelected(event.target.value);
          handleChange(event);
        }}>
        {initialOption ? <option value="">{initialOption}</option> : ""}
        {options.map((option, index) => (
          <option value={option.value} key={option.value}>{option.title}</option>
        ))}
      </Form.Select>
      <ErrorMessage name={field.name} className={"error-message"} component={"div"}/>
    </div>
  );
}
export default DropdownField;
