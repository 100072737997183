import React, {useState, useContext} from "react";
import {useHistory} from "react-router-dom";
import {config} from "../data/constants";
import {fetchPost} from "../utils/index";
import {UserContext} from "../router/AppRouter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const SignIn = () => {
  const classes = useStyles();
  const {dispatch} = useContext(UserContext);
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const PostData = e => {
    e.preventDefault();
    setLoggedIn(true);
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError("Please enter a valid email");
      return;
    }
    let url = config.API_URL + "/api/v1/user/authenticate";
    let data = {
      email,
      password
    };
    fetchPost(url, data).then(res => {
      if (res.success) {
        localStorage.setItem("jwt", res["data"]["token"]);
        localStorage.setItem("user_id", JSON.stringify(res["data"]["user"]["id"]));
        localStorage.setItem("user_name", JSON.stringify(res.data["user"]["first_name"] + " " + res.data["user"]["last_name"]));
        localStorage.setItem("operator_id", JSON.stringify(res["data"]["user"]["role"] === 'operator' ? [res["data"]["user"]["id"]] : res["data"]["operators"]));
        localStorage.setItem("user_role", JSON.stringify(res["data"]["user"]["role"]));
        dispatch({
          type: "USER",
          payload: {
            "user_id": res.data["user"]["id"],
            "user_name": res.data["user"]["first_name"] + " " + res.data["user"]["last_name"],
            "operator_id": res.data["operators"],
            "user_role": res.data["user"]["role"]
          }
        });
        setLoggedIn(false);
        history.push("/trip-dashboard");
      } else {
        setLoggedIn(false);
        alert("Not a valid email or password");
      }
    });
  };

  return (
    <div className="d-flex align-items-center min-vh-100 py-3 py-md-0">
      {loggedIn ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit"/>
        </Backdrop>
      ) : (
        ""
      )}
      <div className={"container"}>
        <div
          className={"row g-0 d-flex justify-content-center align-items-center"}
        >
          <div className={"col-12 col-lg-5"}>
            <div
              className={
                "row g-0 bg-white shadow rounded-2 d-flex justify-content-center align-items-center"
              }
            >
              <div className={"col-12 col-lg-10 py-5"}>
                <h1>iwaycode</h1>
                <h5>Sign in to your account</h5>
                <form onSubmit={PostData}>
                  <div className={"form-row"}>
                    <div className={""}>
                      <input
                        className={"form-control form-control-sm my-3"}
                        type={"email"}
                        placeholder={"Enter Email"}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                      {emailError ? (
                        <small className={"text-danger"}>{emailError}</small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className={"form-row"}>
                    <div className={""}>
                      <input
                        className={"form-control form-control-sm my-3"}
                        type={"password"}
                        placeholder={"Enter Password"}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={"form-row"}>
                    <div className={""}>
                      <button
                        type={"submit"}
                        className={"btn btn-sm btn-primary mt-2"}
                      >
                        Sign-In
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
