import {Backdrop, CircularProgress, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const BackdropLoading = ({showLoader}) => {
  const classes = useStyles();

  return (
    <>
      {showLoader ?
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit"/>
        </Backdrop>
        : ""}
    </>
  );
}

export default BackdropLoading;