import React, {useContext, useEffect, useRef, useState} from "react";
import {config} from "../data/constants";
import {fetchPut, queryFetchGet} from "../utils";
import { Button, Card, Container, Table, Modal } from "react-bootstrap";
import {Link} from "react-router-dom";
import * as YUP from "yup";
import {UserContext} from "../router/AppRouter";
import BackdropLoader from "./common/BackdropLoader";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {Form, Formik} from "formik";
import {InputField} from "./common/InputField";
import DropdownField from "./common/DropdownField";
import {ButtonLoader} from "./common/ButtonLoader";
import $ from "jquery";
import DataTable from 'datatables.net';

const ManageUser = (props) => {
  const history = useHistory();
  const userContext = useContext((UserContext));
  
  let editUserFormikRef = useRef();

  const validationRules = YUP.object({
    id: YUP.string().required("Id is required."),
    first_name: YUP.string().required("First Name is required."),
    email: YUP.string().required("Email is required."),
    password: YUP.string().required("Password is required."),
    operator: YUP.string().required("Operator is required."),
});

  const userUrl = config.API_URL + "/api/v1/user/";
  const operatorUrl = config.API_URL + "/api/v1/user/operators";
  const usersUrl = config.API_URL + "/api/v1/user/users";

  const [operatorId, setOperatorId] = useState();
  const [operatorList, setOperators] = useState([]);
  const [filterOperatorId, setFilterOperatorId] = useState('');
  const [users, setUsers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEditUserModel, setShowEditUserModel] = useState(false);
  const [tableData, setTableData] = useState(false); 
  
  //this code will remove the alert thrown by datatable
  $.fn.dataTable.ext.errMode = 'none';


  useEffect(() => {
    userContext.dispatch({type: "USER", payload: {...userContext.state, "pathname": props.location.pathname}});
  }, []);

  const fetchData = async (url) => {
      let response = await queryFetchGet(url)
      return await response;
  }

  const fetchUserData = async (url, _operator_id, _filterOperatorId) => {
      let operatorIdString = "";
      if (_operator_id && _operator_id.length > 0) {
        operatorIdString = `?operator_id=${JSON.stringify(_operator_id)}`
      }

      if(_filterOperatorId && _filterOperatorId != 'all'){
        operatorIdString = `?operator_id=${JSON.stringify([_filterOperatorId])}`
      }
      
      let response = await queryFetchGet(usersUrl+operatorIdString)
      return await response;
  }

  const operatorReq = useQuery('manageOperatorDataUser', () => fetchData(operatorUrl), {enabled: false})
  const userReq = useQuery(['manageUserData', userContext.state?.operator_id, filterOperatorId], () => fetchUserData(usersUrl, userContext.state?.operator_id, filterOperatorId), {enabled: false})
  
  useEffect(() => {
      operatorReq.refetch();
      userReq.refetch();
  }, [userContext.state?.operator_id]);

  useEffect(() => {
      if (operatorReq.data && operatorReq.data.data) {
          let operatorList = operatorReq.data.data.operatorList
          let _operators = [];
          for (let i = 0; i < operatorList.length; i++) {
            _operators.push({value: operatorList[i]["id"], title: operatorList[i]["first_name"]+operatorList[i]["last_name"]})
          }
          setOperators(_operators);

          $('#operator_table').DataTable().destroy();
          setTimeout(() => {
            initialDatatable();
          }, 0);
      }
  }, [operatorReq.data])

  useEffect(() => {
      if(userReq.data){
          setUsers(userReq.data.data["userList"]);
      }
  }, [userReq.data, users])

  useEffect(() => {
    userReq.refetch();
  }, [filterOperatorId])

  const initialDatatable = () => {
    let table = $('#operator_table').DataTable({
      paging: false,
      "aaSorting": []
    });

    setTableData(table);
  }

  const editUser = (values) => {
    values.operator = [values.operator]
    fetchPut(userUrl, values).then(res => {
      if (res.success) {
        setIsSubmitting(false);
        editUserFormikRef.resetForm();
        setShowEditUserModel(false);
        userReq.refetch();
      }else{
        setIsSubmitting(false);
        alert("Something went wrong.");
      }
    }).catch(err => {
      console.log('Edit Admin Error: ', err)
    });
  }

  return (
    <>
      <BackdropLoader showLoader={operatorReq.isLoading}/>
      <section>
        <Container fluid={true}>
          <div className={"justify-content-between d-flex"}>
            <div className={"h2"}>User</div>
            {userContext?.state?.user_role != 'super_admin' ?
              <div className={"align-items-center d-flex"}>
                <Link className={"btn btn-sm btn-primary mx-1 fs-11-375px"} to={`/add-user`}>
                  Add
                </Link>
              </div>
            : null}
          </div>
          {/* <br/>
          <Card>
            <Table hover responsive size="sm" className={"mb-0"}>
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <th>Operator</th>
                : null}
                <th>Trips</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td></td>
                <td></td>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <td>
                    <select name="operators_select" id="operator-select" onChange={(e) => setFilterOperatorId(e.target.value)}>
                      <option value="all">All</option>
                      {operatorList.map((d, i) => {
                        return(
                          <option key={i} value={d.value}>{d.title}</option>
                        )
                      })}
                    </select>
                    </td>
                  : <td></td>
                }
              </tr>
              {(users && users.length > 0) ? users.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div>{user?.first_name+ ' '+(user?.last_name || "")}</div>
                  </td>
                  <td>
                    <div>{user?.email}</div>
                  </td>
                  {userContext?.state?.user_role === 'super_admin' ? 
                    <td>
                      <div>{operatorList.find(op => op.value == user.operator[0]).title}</div>
                    </td>
                  : null}
                  <td>
                    <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => {history.push(`/trip-dashboard?user_id=${user?.id}`)}}>
                      Trip
                    </Button>
                    <Button variant={"warning"} size={"sm"} className={"mx-2 fs-11-375px"} onClick={() => {
                        setOperatorId(user?.operator[0]);
                        editUserFormikRef.setFieldValue("operator", user?.operator[0])
                        editUserFormikRef.setFieldValue("id", user["id"]);
                        editUserFormikRef.setFieldValue("first_name", user["first_name"]);
                        editUserFormikRef.setFieldValue("last_name", (user?.last_name || ''));
                        editUserFormikRef.setFieldValue("last_name", user["last_name"]);
                        editUserFormikRef.setFieldValue("email", user["email"]);
                        editUserFormikRef.setFieldValue("password", user["password"]);
                        editUserFormikRef.setFieldValue("role", user["role"]);
                        setShowEditUserModel(true);    
                    }
                    }>Edit</Button>
                  </td>
                </tr>)) : ""}
              </tbody>
            </Table>
          </Card> */}
        </Container>
        <div style={{padding: "0px 10px"}}>
          <table id="operator_table" className="display" style={{"width":"100%"}}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <th>Operator</th>
                : <th style={{display: "none"}}/>}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {(users && users.length > 0) ? users.map((user, index) => (
              <tr key={index}>
                <td>
                  <div>{user?.first_name+ ' '+(user?.last_name || "")}</div>
                </td>
                <td>
                  <div>{user?.email}</div>
                </td>
                {userContext?.state?.user_role === 'super_admin' ? 
                  <td>
                    <div>{operatorList.find(op => op.value == user.operator[0]).title}</div>
                  </td>
                : <th style={{display: "none"}}/>}
                <td>
                  <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => {history.push(`/trip-dashboard?user_id=${user?.id}`)}}>
                    Trip
                  </Button>
                  <Button variant={"warning"} size={"sm"} className={"mx-2 fs-11-375px"} onClick={() => {
                      setOperatorId(user?.operator[0]);
                      editUserFormikRef.setFieldValue("operator", user?.operator[0])
                      editUserFormikRef.setFieldValue("id", user["id"]);
                      editUserFormikRef.setFieldValue("first_name", user["first_name"]);
                      editUserFormikRef.setFieldValue("last_name", (user?.last_name || ''));
                      editUserFormikRef.setFieldValue("last_name", user["last_name"]);
                      editUserFormikRef.setFieldValue("email", user["email"]);
                      editUserFormikRef.setFieldValue("password", user["password"]);
                      editUserFormikRef.setFieldValue("role", user["role"]);
                      setShowEditUserModel(true);    
                  }
                  }>Edit</Button>
                </td>
              </tr>)) 
            : ""}
            </tbody>
          </table>
        </div>
        <br/>
        <Formik
          initialValues={{
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role: "user",
            operator: ""
          }}
          onSubmit={values => {
            editUser(values);
          }}
          innerRef={(p) => {
            editUserFormikRef = p
          }}
          validationSchema={validationRules}>
          {formik => (
            <Modal show={showEditUserModel}>
              <Form>
                <Modal.Header>
                  <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"pt-0"}>
                    <InputField label={null} type={"text"} name={"id"} className={"d-none"}/>
                    <InputField label={"First Name"} type={"text"} name={"first_name"} placeholder={"Enter the first name"}/>
                    <InputField label={"Last Name"} type={"text"} name={"last_name"} placeholder={"Enter the last name"}/>
                    <InputField label={"Email"} type={"text"} name={"email"} placeholder={"Enter the email"}/>
                    <InputField autocomplete="new-password" label={"Password"} type={"password"} name={"password"} placeholder={"Enter the password"}/>
                    <InputField label={null} type={"text"} name={"role"} className={"d-none"}/>
                    <div className={"d-none"}>
                      <DropdownField
                          label={"Operator"} name={"operator"} placeholder={"Select a Operator"} options={operatorList}
                          disabled={true}
                          selectedValue={operatorId} initialOption={"Select a Operator"}
                          handleChange={(event) => {
                              formik.setFieldValue("operator", event.target.value);
                          }}
                          onOptionChange={(event => {
              
                          })}
                      />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size={"sm"} className={"mt-2 fs-11-375px"} 
                        onClick={() => {
                            setShowEditUserModel(false);
                            formik.resetForm();
                        }}
                    >
                    Cancel
                  </Button>
                  <ButtonLoader type={"submit"} title={"Update"} classes={"btn btn-sm btn-primary mt-2 fs-11-375px"} loading={isSubmitting}/>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </Formik>
      </section>
    </>
  );
}

export default ManageUser;